import React,{Component} from 'react'
import axios from 'axios/index'
import { APIURL } from '../../../config/config'

const port = window.env.PORT;

class CarLeasingFormNew extends Component{
  constructor (props){
    super(props)
    this.state = {
      lists: [],
      car_id : this.props.car.car_id,
      // leasingName: ['SCB', 'GE', 'TA', 'KK', 'TIS'],
      // leasingName: ['AYCAL', 'TBANK', 'TISCO', 'CIMB', 'SCB'],
      leasingName: [],
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
  }

  componentDidMount() {
    this.mounted = true
    this.loadCarLeasingForm();
    this.loadCarLeasing();
  }

  componentWillUnmount() {
    this.mounted = false
  }

  loadCarLeasingForm() {
    var url = APIURL + '/leasing_setting/';
    axios.get(url)
      .then(res => {
        if(this.mounted) {
          this.setState({ leasingName: res.data })
        }
      });
  }

  loadCarLeasing() {
    var uri =  APIURL + '/car_leasing/'+this.state.car_id;
    axios.get(uri)
      .then(res => {
        if(this.mounted) {
          if (res.data.length === 0) {
            this.setState({ lists: res.data, action: 'add' })
          } else {
            this.setState({ lists: res.data, action: 'edit' })
          }
        }

      }).catch(error => {
        if(this.mounted) {
          this.setState({ lists: [] })
        }
    })
  }

  handleChangeInput(event) {
    var targetName = event.target.name;
    var value = event.target.value;
    var leasingNameUpdate =  this.state.leasingName
    var leasingNew = []

   if (port === '9021' && this.props.user_type === 'sale') {
     return
   }

    let name_median = leasingNameUpdate.filter(leasing => leasing.name_median === targetName)
      .map(
        leasing => leasing.name_median === targetName ? {...leasing, value_median: value} : leasing
      )

    let name_credit = leasingNameUpdate.filter(leasing => leasing.name_credit === targetName)
      .map(
        leasing => leasing.name_credit === targetName ? {...leasing, value_credit: value} : leasing
      )

    let name_no_credit = leasingNameUpdate.filter(leasing => leasing.name_no_credit === targetName)
      .map(
        leasing => leasing.name_no_credit === targetName ? {...leasing, value_no_credit: value} : leasing
      )

    if(name_median.length > 0) {
      leasingNew =  leasingNameUpdate.map(
        leasing => leasing.name_median === targetName ? name_median[0] : leasing
      )
    }

    if(name_credit.length > 0) {
      leasingNew =  leasingNameUpdate.map(
        leasing => leasing.name_credit === targetName ? name_credit[0] : leasing
      )
    }

    if(name_no_credit.length > 0) {
      leasingNew =  leasingNameUpdate.map(
        leasing => leasing.name_no_credit === targetName ? name_no_credit[0] : leasing
      )
    }

    this.setState({
      leasingName: leasingNew
    });
  }

  handleChange(event) {
    event.preventDefault();

    var targetName = event.target.name;
    var value = event.target.value;
    var listsUpdate =  this.state.lists
    var listsUpdateNew = []
    var leasingNameUpdate =  this.state.leasingName

    var name_median = leasingNameUpdate.filter(leasing => leasing.name_median === targetName)
      .map(
        leasing => leasing.name_median === targetName ? {...leasing, value_median: value} : leasing
      )

    var name_credit = leasingNameUpdate.filter(leasing => leasing.name_credit === targetName)
      .map(
        leasing => leasing.name_credit === targetName ? {...leasing, value_credit: value} : leasing
      )

    var name_no_credit = leasingNameUpdate.filter(leasing => leasing.name_no_credit === targetName)
      .map(
        leasing => leasing.name_no_credit === targetName ? {...leasing, value_no_credit: value} : leasing
      )

    if(name_median.length > 0) {
      listsUpdateNew =  listsUpdate.map(
        leasing => leasing.car_leasing_name+'M' === targetName ? {...leasing,car_leasing_price_median: value } : leasing
      )
    }

    if(name_credit.length > 0) {
      listsUpdateNew =  listsUpdate.map(
        leasing => leasing.car_leasing_name === targetName ? {...leasing,car_leasing_price: value } : leasing
      )
    }

    if(name_no_credit.length > 0) {
      listsUpdateNew =  listsUpdate.map(
        leasing => leasing.car_leasing_name+'2' === targetName ? {...leasing,car_leasing_price_nc: value } : leasing
      )
    }

    this.setState({
      lists: listsUpdateNew
    });

  }

  render() {
    var trLeasing = ''
    if(this.state.lists.length === 0){
      trLeasing = this.state.leasingName.map((leasing, index) => {
        var input = ''
        var input2 = '';
        var inputM = '';

        input = (
          <input
            className="form-control"
            type="number"
            id={leasing.name_credit}
            name={leasing.name_credit}
            onChange={this.handleChangeInput}
          />
        )
        inputM = (
          <input
            className="form-control"
            type="number"
            id={leasing.name_median}
            name={leasing.name_median}
            onChange={this.handleChangeInput}
          />
        )
        input2= ( <input
          className="form-control"
          type="number"
          id={leasing.name_no_credit}
          name={leasing.name_no_credit}
          onChange={this.handleChangeInput}
        />)

         return (
           <tr key={index}>
             <td style={{width: 100}}>{leasing.name_credit}</td>
             <td style={{width: 150}}>
               {inputM}
             </td>
             <td style={{width: 150}}>
               {input}
             </td>
             <td style={{width: 150}}>
               {input2}
             </td>
           </tr>
         )
      })
    }else{

      trLeasing = this.state.lists.map((leasing, index) => {
        var name_credit = leasing.car_leasing_name
        var name_median = leasing.car_leasing_name+'M'
        var name_no_credit = leasing.car_leasing_name+'2'

        // var value_credit = leasing.car_leasing_price
        // var value_median = leasing.car_leasing_price_median
        // var value_no_credit = leasing.car_leasing_price_nc

        var inputM = '';
        var input = '';
        var input2 = '';


        inputM = (
          <input
            className="form-control"
            type="number"
            id={name_median}
            name={name_median}
            value={this.state.lists[index].car_leasing_price_median || 0}
            onChange={this.handleChange}
          />
        )

        input= ( <input
          className="form-control"
          type="number"
          id={name_credit}
          name={name_credit}
          value={this.state.lists[index].car_leasing_price || 0}
          onChange={this.handleChange}
        />)

        input2= ( <input
          className="form-control"
          type="number"
          id={name_no_credit}
          name={name_no_credit}
          value={this.state.lists[index].car_leasing_price_nc || 0}
          onChange={this.handleChange}
        />)

        return (
          <tr key={index}>
            <td style={{width: 100}}>{name_credit}</td>
            <td style={{width: 150}}>
              {inputM}
            </td>
            <td style={{width: 150}}>
              {input}
            </td>
            <td style={{width: 150}}>
              {input2}
            </td>
          </tr>
        )

      })


    }

    return (
      <div className="card card-accent-primary">
        <form action="" method="post" onSubmit={(event) => this.state.action==='add' ? this.props.onSubmit(event, this.state.leasingName) : this.props.onSubmit(event, this.state.lists) }>
          <div className="card-header">
            <strong>บันทึกยอดจัด</strong>
          </div>
          <div className="card-block">
            <div className="row text-center">
              <table className="tableWhitesmoke" style={{marginTop: 8}}>
                <thead>
                {
                  port === '9021' ? (
                      <tr>
                        <th className="text-center" >ลิสซิ่ง</th>
                        <th className="text-center" >ปกติ</th>
                        <th className="text-center" >เครดิต</th>
                        <th className="text-center" >เครดิตพิเศษ</th>
                      </tr>
                    ) :
                    (
                      <tr>
                        <th className="text-center" >ลิสซิ่ง</th>
                        <th className="text-center" >ราคากลาง</th>
                        <th className="text-center" >เครดิต</th>
                        <th className="text-center" >ไม่เครดิต</th>
                      </tr>
                    )
                }

                </thead>
                <tbody>
                {trLeasing}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-footer text-right">

            <button type="reset"
                    onClick={this.props.onHandle.cancel}
                    className="btn btn-sm btn-danger mr-2"
                    style={{width: 80}}
            >
              <i className="fa fa-refresh"/> ปิด
            </button>

            {
              port === '9021' && this.props.user_type === 'sale' ? null : (
                <button type="submit"
                        className={this.state.action==='add'? "btn btn-sm btn-primary" : "btn btn-sm btn-warning" }
                        style={{width: 80}}
                >
                  <i className="fa fa-save"/>
                  {this.state.action==='add'? " บันทึก" : " แก้ไข" }
                </button>
              )
            }

            <input type="hidden" name="action" defaultValue={this.state.action}  />

          </div>
        </form>
      </div>
    )
  }
}

export default CarLeasingFormNew
