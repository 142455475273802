import React, { Component } from 'react'
import CustomerInstallmentHistoryForm from "./CustomerInstallmentHistoryForm";
import {APIURL} from "../../config/config";
import axios from "axios";
import {AlertSuccess} from "../Alert/Alert";
import Swal from "sweetalert2";

class CustomerInstallmentHistory extends Component{
    constructor (props) {
        super(props)

        this.state = {
            is_modal: false,
            width: 0,
            height: 0,
            customer_installment_history: [],
            customer_installment_history_id: 0,
            customer_installment_history_edit: null,
            form_status: 'add'
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.loadCustomerInstallmentHistory = this.loadCustomerInstallmentHistory.bind(this);
        this.onClickEdit = this.onClickEdit.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);

    }

    componentDidMount () {
        this.loadCustomerInstallmentHistory()
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    loadCustomerInstallmentHistory() {
        let url = APIURL +'/customer_installment_history'
        axios.get(url).then(res => {
            let result = res.data;
            this.setState({
                customer_installment_history: result
            })
        })
    }

    onClickEdit(item) {
        this.setState({
            customer_installment_history_id: item.id,
            customer_installment_history_edit: item,
            form_status: 'edit',
            is_modal: true
        })
    }

    onClickDelete(item) {
        let finance_name = item.finance_name
        let fullname = item.customer_name+' '+item.customer_lastname

        Swal.fire({
            title: 'ยืนยันการลบประวัติการผ่อน '+finance_name + ' ของ '+fullname,
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
        }).then(result => {
            if(result.value){
                let id = item.id
                let url = APIURL +'/customer_installment_history/'+id
                axios.delete(url).then(res => {
                    let result = res.data;
                    if(result === 'success') {
                        AlertSuccess('ลบบ้อมูลเรียบร้อยแล้ว');

                        this.loadCustomerInstallmentHistory()
                    }
                })
            }
        })
    }

    render() {

        let customer_installment_history = this.state.customer_installment_history

        let empty_row = <tr><td className="text-center text-danger" colSpan={7}>ไม่พบข้อมูล</td></tr>
        let customer_installment_history_rows = customer_installment_history.map((item, index) => {

            return (
                <tr key={index}>
                    <td className="text-center">{ index+1}</td>
                    <td className="text-left" style={{width: 250}}>
                        ชื่อ-นามสกุล : {item.customer_name}{' '} {item.customer_lastname} <br/>
                        โทร: {item.customer_mobile===''? '-' : item.customer_mobile}
                    </td>
                    <td className="text-left"> ชื่อไฟแนนซ์ : {item.finance_name} </td>
                    <td className="text-left"> ประวัติการผ่อน : {item.installment_history_name}</td>
                    <td className="text-left"> สกอร์ไฟแนนซ์  : {item.finance_score}</td>
                    <td className="text-left"> เครดิตปัจจุบัน  : {item.finance_credit}</td>
                    <td className="text-center">
                        <button className="btn btn-sm btn-warning btn-md-width mr-2 mb-2"
                                style={styles.button}
                                onClick={() => this.onClickEdit(item)}
                        >
                            <i className="fa fa-edit"/> แก้ไข
                        </button>

                        <button className={"btn btn-sm btn-danger btn-md-width mb-2 mr-2"}
                                onClick={() => this.onClickDelete(item)}
                                style={styles.button}
                        >
                            <i className="fa fa-remove"/> ลบ</button>

                    </td>
                </tr>
            )

        })

       let customer_installment_history_list = customer_installment_history.length === 0 ? empty_row : customer_installment_history_rows


        return (
            <>

                {
                    this.state.is_modal
                    &&
                    <CustomerInstallmentHistoryForm
                        is_modal={this.state.is_modal}
                        width={this.state.width}
                        username={this.props.username}
                        toggle={() => {
                            this.setState({is_modal: false})
                        }}
                        form_status={this.state.form_status}
                        customer_installment_history_id={this.state.customer_installment_history_id}
                        customer_installment_history_edit={this.state.customer_installment_history_edit}
                        closeAndReload={() => {
                            this.setState({is_modal: false, form_status: 'add'})
                            this.loadCustomerInstallmentHistory()
                        }}
                    />
                }

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header card-customer">
                                <strong className="text-title">ประวัติการผ่อน</strong>
                                <button className="btn btn-head-bar float-right"  onClick={() => {
                                    this.setState({
                                        is_modal: true,
                                        form_status: 'add',
                                    })
                                }} >
                                    <i className="icon-plus"/>&nbsp; เพิ่มประวัติการผ่อน
                                </button>
                            </div>

                            <div className="card-block">

                                <table className="table table-striped table-responsive">
                                    <thead>
                                    <tr>
                                        <th className="text-center" style={{width: 10}}>#</th>
                                        <th className="text-center text-nowrap">ลูกค้า</th>
                                        <th className="text-left text-nowrap">ชื่อไฟแนนซ์</th>
                                        <th className="text-left text-nowrap">ประวัติการผ่อน</th>
                                        <th className="text-left text-nowrap">สกอร์ไฟแนนซ์</th>
                                        <th className="text-left text-nowrap">เครดิตปัจจุบัน</th>

                                        <th className="text-center" style={{width: 80}}>จัดการ</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {customer_installment_history_list}
                                    </tbody>
                                </table>

                            </div>

                        </div>
                    </div>
                </div>

            </>
        )
    }
}

const styles = {
    button: {
        borderRadius:5,
        height: 36,
    }
}

export default CustomerInstallmentHistory
