import * as XLSX from "xlsx-js-style";

export const ExcelUtil = {
    getBorderCell() {
        const cellTable = {
            top: { style: 'thin', color: { rgb: "000000" } },
            bottom: { style: 'thin', color: { rgb: "000000" } },
            left: { style: 'thin', color: { rgb: "000000" } },
            right: { style: 'thin', color: { rgb: "000000" } },
        }
        return cellTable
    },
    setCellBoldWithBorder(ws, rangeTitle) {
        // cell.s = {alignment:{ wrapText: true }}
        for(let R = rangeTitle.s.r; R <= rangeTitle.e.r; ++R) {
            for(let C = rangeTitle.s.c; C <= rangeTitle.e.c; ++C) {
                let cell = ws[XLSX.utils.encode_cell({r:R,c:C})];

                if(!cell){
                    continue;
                }
                cell.s = {
                    alignment: { horizontal: "center", wrapText: true },
                    font: { bold: true},
                    border: this.getBorderCell()
                }
            }
        }
    },
    setCellTextRightBoldWithBorder(ws, rangeTitle) {
        // cell.s = {alignment:{ wrapText: true }}
        for(let R = rangeTitle.s.r; R <= rangeTitle.e.r; ++R) {
            for(let C = rangeTitle.s.c; C <= rangeTitle.e.c; ++C) {
                let cell = ws[XLSX.utils.encode_cell({r:R,c:C})];

                if(!cell){
                    continue;
                }
                cell.s = {
                    alignment: { horizontal: "right", wrapText: true },
                    font: { bold: true},
                    border: this.getBorderCell()
                }
            }
        }
    },
    setCellCenterNormalWithBorder(ws, rangeOrder){
        for(let R = rangeOrder.s.r; R <= rangeOrder.e.r; ++R) {
            for(let C = rangeOrder.s.c; C <= rangeOrder.e.c; ++C) {
                let cell = ws[XLSX.utils.encode_cell({r:R,c:C})];

                if(!cell) continue;

                cell.s = {
                    alignment: { horizontal: "center" },
                    border: this.getBorderCell()
                }
            }
        }
    },

    setCellNumberFormatWIthBorder(ws, rangeNumber) {
        let fmt = '#,##0.00';
        for(let R = rangeNumber.s.r; R <= rangeNumber.e.r; ++R) {
            for(let C = rangeNumber.s.c; C <= rangeNumber.e.c; ++C) {
                let cell = ws[XLSX.utils.encode_cell({r:R,c:C})];

                if(!cell) continue;

                cell.s = {
                    border: this.getBorderCell()
                }

                if(!cell || cell.t != 'n') continue; // only format numeric cells

                cell.z = fmt;

                cell.s = {
                    border: this.getBorderCell()
                }
            }
        }
    },
    setCellNumberFormatBlueWithBorder(ws, rangeNumber) {
        let fmt = '#,##0.00';
        let fontStyle = { color: { rgb: "164E9B" } }
        for(let R = rangeNumber.s.r; R <= rangeNumber.e.r; ++R) {
            for(let C = rangeNumber.s.c; C <= rangeNumber.e.c; ++C) {
                let cell = ws[XLSX.utils.encode_cell({r:R,c:C})];

                if(!cell) continue;

                cell.s = {
                    border: this.getBorderCell(),
                    font: fontStyle
                }

                if(!cell || cell.t != 'n') continue; // only format numeric cells

                cell.z = fmt;

                cell.s = {
                    border: this.getBorderCell(),
                    font: fontStyle
                }
            }
        }
    }
}