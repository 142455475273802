
class UserUtil {
  convertUserTypeToText(type) {
    let typeName = ''
    switch (type){
      case 'admin':
        typeName = 'ผู้ดูแลระบบ'
        break
      case 'sale':
        typeName = 'พนักงานขาย'
        break
      case 'technician':
        typeName = 'ช่างเทคนิค'
        break
      case 'account':
        typeName = 'บัญชี'
        break
      default :
        typeName = ''
        break
    }
    return typeName
  }
}

export default new UserUtil()
