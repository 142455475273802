import React, { Component } from 'react'
// import { Dropdown, DropdownMenu, DropdownItem } from 'reactstrap'
import { connect } from 'react-redux'
import { logoutAndRedirect } from '../../actions/index'
import PropTypes from 'prop-types'
import {CarSellUtil, CarsUtil} from "../../utils/carSellUtil";

class Header extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      user: {}
    };
    this.onLogoutAction = this.onLogoutAction.bind(this);
  }

  componentWillMount() {
    // const queryString = require('query-string');
    this.setState({
      user: JSON.parse(this.props.user),
    })
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  sidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  }

  sidebarMinimize(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-minimized');
  }

  mobileSidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  }

  asideToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('aside-menu-hidden');
  }

  onLogoutAction(){
    CarsUtil.removeCarSelect()
    CarSellUtil.removeCarSellSelect()
    //alert('Logout');
    this.props.onLogout();
    this.props.history.push('/login');
  }

  render() {
   let logo_url = ''
   let logo_image = this.props.logo_image
   // let token = this.props.token
   // let type = this.props.type

   logo_url = logo_image === undefined || logo_image === null ? "url('../img/logo.png')" : "url('"+logo_image+"')"


    return (
      <header className="app-header navbar">
        <button className="navbar-toggler mobile-sidebar-toggler d-lg-none" type="button" onClick={this.mobileSidebarToggle}>&#9776;</button>

        {
          logo_url === '' ? (
            <a className="navbar-brand"
               href="#/dashboard">&nbsp;</a>
          ) : (
            <a className="navbar-brand"
               style={{backgroundImage: logo_url, backgroundSize: '40px'}}
               href="#/dashboard">&nbsp;</a>
          )
        }


        <ul className="nav navbar-nav d-md-down-none">
          <li className="nav-item">
            <button className="nav-link navbar-toggler sidebar-toggler" type="button" onClick={this.sidebarToggle}>&#9776;</button>
          </li>
          <li className="nav-item px-3">
            <a className="nav-link" href="#/dashboard">หน้าหลัก</a>
          </li>
          <li className="nav-item px-3">
            <a className="nav-link" href="#/cars/list">ข้อมูลรถ</a>
          </li>
        </ul>
        <ul className="nav navbar-nav ml-auto">
          {/*  <li className="nav-item d-md-down-none">
            <a className="nav-link" href="#">
              <i className="icon-bell"></i>
              <span className="badge badge-pill badge-danger">5</span>
            </a>
          </li>
          <li className="nav-item d-md-down-none">
            <a className="nav-link" href="#"><i className="icon-list"></i></a>
          </li>
          <li className="nav-item d-md-down-none">
            <a className="nav-link" href="#"><i className="icon-location-pin"></i></a>
          </li> */}
          <li className="nav-item">
            <button onClick={this.toggle} className="nav-link  d-md-down-none " data-toggle="dropdown" type="button" aria-haspopup="true" aria-expanded={this.state.dropdownOpen}>
              <img src={'img/avatars/2.jpg'} className="img-avatar" alt="avatars_appxar" />
              <span className="d-md-down-none">{(this.state.user === null)? '' : this.state.user.user}  | </span>
            </button>
            {/*
            dropdown-toggle
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
              <DropdownMenu className="dropdown-menu-right">
                <DropdownItem header className="text-center"><strong>Account</strong></DropdownItem>
                <DropdownItem><i className="fa fa-bell-o"></i> Updates<span className="badge badge-info">42</span></DropdownItem>
                <DropdownItem><i className="fa fa-envelope-o"></i> Messages<span className="badge badge-success">42</span></DropdownItem>
                <DropdownItem><i className="fa fa-tasks"></i> Tasks<span className="badge badge-danger">42</span></DropdownItem>
                <DropdownItem><i className="fa fa-comments"></i> Comments<span className="badge badge-warning">42</span></DropdownItem>
                <DropdownItem header className="text-center"><strong>Settings</strong></DropdownItem>
                <DropdownItem><i className="fa fa-user"></i> Profile</DropdownItem>
                <DropdownItem><i className="fa fa-wrench"></i> Settings</DropdownItem>
                <DropdownItem><i className="fa fa-usd"></i> Payments<span className="badge badge-default">42</span></DropdownItem>
                <DropdownItem><i className="fa fa-file"></i> Projects<span className="badge badge-primary">42</span></DropdownItem>
                <DropdownItem divider />
                <DropdownItem><i className="fa fa-shield"></i> Lock Account</DropdownItem>
                <DropdownItem onClick={this.onLogoutAction}><i className="fa fa-lock"></i> Logout</DropdownItem>
              </DropdownMenu>
            </Dropdown>*/}
          </li>
          <li className="nav-item d-md-down-none">
            <button className="nav-link" onClick={this.onLogoutAction} >&nbsp;&nbsp;<i className="fa fa-lock"></i> Logout </button>
          </li>
          {/*<li className="nav-item d-md-down-none">
            <button className="nav-link navbar-toggler aside-menu-toggler" type="button" onClick={this.asideToggle}>&#9776;</button>
          </li>*/}
          <li className="nav-item d-md-down-none">
            &nbsp;
          </li>
        </ul>
      </header>
    )
  }
}

Header.propTypes = {
  onLogout: PropTypes.func
}
//export default Header;
const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  type: state.auth.type,
  logo_image: state.auth.logo_image,
  isAuthenticated: state.auth.isAuthenticated
})

const mapDispatchToProps = (dispatch) => ({
  onLogout() {
    dispatch(logoutAndRedirect())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)