/**
 * Created by devmaster on 7/23/2017 AD.
 */

/*import data from './config.json';
let port = data.PORT;
let dir = data.BASE_DIR;*/

// IMAGE_THUMB_URL
export const PORT = Number(window.env.PORT);
export const BASE_DIR = window.env.BASE_DIR
export const APIURL = window.env.APIURL
export const IMAGE_FULL_URL  = window.env.IMAGE_FULL_URL
export const IMAGE_THUMB_URL = window.env.IMAGE_THUMB_URL
export const IMAGE_NOPHOTO_URL = window.env.IMAGE_NOPHOTO_URL
export const IMAGE_UPLOAD_ERROR = window.env.IMAGE_UPLOAD_ERROR
export const IMAGE_PDF_URL = window.env.IMAGE_PDF_URL

export const WEBURL = window.env.WEBURL
export const WEBSITEURL = window.env.WEBSITEURL
export const PAGE10 = window.env.PAGE10
export const PAGE20 = 20
export const __DEV__ = true

/*
export const APIURL = 'https://bosscarsoft.com/api'+port+'/v1'; //:9000
export const IMAGE_FULL_URL  = 'https://bosscarsoft.com/'+dir+'/car_photos/';
export const IMAGE_THUMB_URL = 'https://bosscarsoft.com/'+dir+'/car_photos/thumb/';
export const IMAGE_NOPHOTO_URL = 'https://bosscarsoft.com/'+dir+'/img/nophoto.png';
export const WEBURL = 'https://bosscarsoft.com/'+dir+'/'
export const PAGE10 = 10;
export const PORT = port;
export const BASE_DIR = dir;*/


/*
export const APIURL = 'https://api.pattayausedcars9.com/api/v1'; //:9000
export const IMAGE_FULL_URL  = 'https://pattayausedcars9.com/'+dir+'/car_photos/';
export const IMAGE_THUMB_URL = 'https://pattayausedcars9.com/'+dir+'/car_photos/thumb/';
export const IMAGE_NOPHOTO_URL = 'https://pattayausedcars9.com/'+dir+'/img/nophoto.png';
export const WEBURL = 'https://pattayausedcars9.com/'+dir+'/'
export const PAGE10 = 10;
export const PORT = port;
export const BASE_DIR = dir;*/

/*
// var hostname = window.location.hostname
export const APIURL = 'https://api.jwsmartcar.com/api/v1'; //:9000
export const IMAGE_FULL_URL  = 'https://jwsmartcar.com/'+dir+'/car_photos/';
// export const IMAGE_THUMB_URL =  hostname+'/'+dir+'/car_photos/thumb/';
// export const IMAGE_NOPHOTO_URL = hostname+'/'+dir+'/img/nophoto.png';
export const IMAGE_THUMB_URL = 'https://jwsmartcar.com/'+dir+'/car_photos/thumb/';
export const IMAGE_NOPHOTO_URL = 'https://jwsmartcar.com/'+dir+'/img/nophoto.png';
export const WEBURL = 'https://jwsmartcar.com/'+dir+'/'
export const PAGE10 = 10;
export const PORT = port;
export const BASE_DIR = dir;*/

// console.log(IMAGE_THUMB_URL);
// console.log(IMAGE_NOPHOTO_URL);
