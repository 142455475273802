import React, { Component } from 'react';
import Loading from '../Loading';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import axios from 'axios';
import { APIURL } from '../../config/config';
import { save } from 'save-file';
import { AlertWarning } from '../Alert/Alert'

class CarbookReport extends Component{
  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      date_start: new Date(),
      date_end: new Date(),
      data_by: 'byDate', // all, byDate
      lists:[],
      branches: [],
      branch_id: ''
    }

    this.handleDateStart = this.handleDateStart.bind(this)
    this.handleDateEnd = this.handleDateEnd.bind(this)
    this.handleOnChange = this.handleOnChange.bind(this)
    this.loadBranchData = this.loadBranchData.bind(this)
    this.loadCarBookData = this.loadCarBookData.bind(this)
    this.exportCarBookData = this.exportCarBookData.bind(this)

  }

  componentDidMount () {
    this.loadBranchData()
  }

  handleOnChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleDateStart(date){
    this.setState({
      date_start: date
    })
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    })
  }

  loadBranchData () {
    axios.get(APIURL + '/branch/')
      .then(res => {
        this.setState({branches: res.data}, () => this.loadCarBookData())
      }).catch(error => {
      this.setState({branches: []})
    })
  }

  loadCarBookData() {
    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    const data_by = this.state.data_by
    const branch_id = this.state.branch_id

    const data = {
      start_date: start_date,
      end_date: end_date,
      data_by: data_by,
      branch_id: branch_id
    }

    this.setState({
      isLoading: true
    }, () => {
      axios.post(APIURL + '/report/carbook', data)
        .then(res => {
          this.setState({
            isLoading: false,
            lists: res.data
          }, () => {
            // this.showBranchSummary()
          })

        }).catch(error => {
        console.log('error:', error)
      })
    })
    // axios.get(APIURL + '/report/carin/'+start_date+'/'+end_date)

  }

  async exportCarBookData() {

    if(this.state.date_start > this.state.date_end){
      alert('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    const data_by = this.state.data_by
    const branch_id = this.state.branch_id

    const data = {
      start_date: start_date,
      end_date: end_date,
      data_by: data_by,
      branch_id: branch_id
    }

    this.setState({
      isLoading: true
    }, () => {
      axios.post(APIURL + '/report/carbookexcel', data)
        .then(async res => {

          const excelBuffer = res.data.excelBuffer
          const currentDatetime = res.data.currentDatetime
          const fileName = 'รายงานการจอง_export_'+currentDatetime+'.xlsx'

          this.setState({
            isLoading: false
          })

          await save(excelBuffer, fileName)

        }).catch(error => {
        //console.log('error carinexport:', error)
      })
    })

  }

  render () {
    const {
      lists
    } = this.state

    let car_branch_list = this.state.branches.map((branch, index) => {
      return (
        <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
      )
    })

    const emptyBody = <tr><td colSpan={18} className="text-center text-danger" > ไม่พบข้อมูล </td></tr>

    const listRows = lists.map((car, index) => {

      let car_book_date = moment(car.car_book_date).format('YYYY-MM-DD')
      // console.log('car.car_book_date=', car.car_book_date)
      // console.log('car_book_date=', car_book_date)

      //let car_license_plate = car.car_license_plate_new === '' ? car.car_license_plate_old : car.car_license_plate_new

      const car_license_plate = car.car_license_plate_new !== '' &&  car.car_license_plate_new !==  car.car_license_plate_old ?
          car.car_license_plate_new : car.car_license_plate_old

      const province_name = car.car_license_plate_new !== '' &&  car.car_license_plate_new !==  car.car_license_plate_old ?
          car.province_name_new : car.province_name_old

      let car_book_status = '';
      if (car.car_book_status_id === 1) { // 1 = จอง 2= ยกเลิก
        car_book_status = 'จอง'
      } else {
        car_book_status = 'ยกเลิก'
      }

      return (
        <tr key={index}>
          <td className="text-center">{index+1}</td>
          <td className="text-center text-nowrap">{car_book_date}</td>
          <td className="text-left text-nowrap">{car.car_name}</td>
          <td className="text-center">{car.car_status_name}</td>
          <td className="text-center text-nowrap">{car_license_plate}{' '}{province_name}</td>
          <td className="text-center text-nowrap">{car.car_sale_price.toLocaleString()}</td>
          <td className="text-center text-nowrap">{car.cost_car_book.toLocaleString()}</td>
          <td className="text-center text-nowrap">{car.cost_car_finance.toLocaleString()}</td>

          <td className="text-center text-nowrap">{car.cost_down.toLocaleString()}</td>
          <td className="text-center text-nowrap">{car.cost_pledge.toLocaleString()}</td>
          <td className="text-center text-nowrap">{car.car_sell_pay_count.toLocaleString()}</td>
          <td className="text-center text-nowrap">{car.user_receive_money}</td>

          <td className="text-center text-nowrap">{car.customer_name}{' '}{car.customer_lastname}</td>
          <td className="text-center text-nowrap">{car.customer_mobile}</td>
          <td className="text-center text-nowrap">{car.insurance_type_name}</td>
          {/*<td className="text-center text-nowrap">{car.user_book}</td>*/}
          <td className="text-center text-nowrap">{car.booking_by}</td>

          <td className="text-center text-nowrap">{car.profile_from}</td>
          <td className="text-center text-nowrap">{car_book_status}</td>
        </tr>
      )
    })

    const tableRows = lists.length === 0 ? emptyBody : listRows

    return (
      <div>
        <Loading isLoading={this.state.isLoading} />
        <div className="card-block" style={styles.overflowY}>
          <div className="row">
            <div className="card card-accent-primary">
              <div className="card-header">
                <div className="row col-12 mt-1">
                  <div className="col-6">
                    <strong className="text-title">รายงานการจอง</strong>
                  </div>
                  <div className="col-3">

                  </div>

                  {
                    this.state.lists.length === 0 ? '': (
                      <div className="col-3 text-center pt-2 pb-1 bg-primary">
                        <h5>
                          พบ&nbsp; {this.state.lists.length} &nbsp;รายการ
                        </h5>
                      </div>
                    )
                  }

                </div>
              </div>

              <div className="card-block">

                <div className="row mt-1">
                  <div className="mt-1 ml-4">
                    จาก
                  </div>

                  <div className="form-group col-2 ml-2">
                    <DatePicker
                      selected={this.state.date_start}
                      onChange={this.handleDateStart}
                      dateFormat="yyyy-MM-dd"
                      type="text"
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                  {/* <div className="col-1 text-center">
                      ถึง
                    </div>*/}
                  <div className="form-group col-2">
                    <DatePicker
                      selected={this.state.date_end}
                      onChange={this.handleDateEnd}
                      dateFormat="yyyy-MM-dd"
                      type="text"
                      className="form-control"
                      placeholder=""
                    />
                  </div>

                  <div className="form-group col-2">
                    <select className="form-control" id="branch_id" name="branch_id"
                            onChange={(e) => {
                              this.setState({
                                branch_id: e.target.value
                              })
                            }}
                    >
                      <option value=''> ตามสาขา </option>
                      {car_branch_list}
                    </select>
                  </div>

                  <div className="form-group mt-1 ml-3">
                    <input type="radio"
                           name="data_by"
                           value="byDate"
                           className="btn btn-primary"
                           checked={this.state.data_by === 'byDate'}
                           onChange={this.handleOnChange}
                    /> &nbsp;<span style={{marginTop: -4}}>ตามวันที่</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="radio"
                           name="data_by"
                           value="all"
                           className="btn btn-primary"
                           checked={this.state.data_by === 'all'}
                           onChange={this.handleOnChange}
                    /> &nbsp;<span style={{marginTop: -4}}>ทั้งหมด</span>
                  </div>



                  <div className="col-1 ml-4 mr-1">
                    <button type="button" style={{width: 80}} className="btn btn-sm btn-primary" onClick={() => this.loadCarBookData()} > ตกลง </button>
                  </div>

                  <div className="col-1 ml-1">
                    <button type="button" style={{width: 80}} className="btn btn-sm btn-success" onClick={() => this.exportCarBookData()} > Excel </button>
                  </div>

                </div>

                <div className="row mt-1">
                  <div className="col-12">

                    <div>
                      <span className="text-danger">***แสดงทุกรายการที่มีการจอง บางคันมีการจองมากกว่า 1 รายการ</span>
                    </div>
                    <table className="table table-striped table-responsive">
                      <thead>
                      <tr>
                        <th style={{width: 10}} className="text-center text-nowrap">ลำดับ</th>
                        <th style={{width: 60}} className="text-center text-nowrap">วันที่จอง</th>
                        <th style={{width: 150}} className="text-center text-nowrap">ชื่อรถ</th>
                        <th style={{width: 150}} className="text-center text-nowrap">สถานะรถ</th>
                        <th style={{width: 50}} className="text-center text-nowrap">ทะเบียน</th>
                        <th style={{width: 50}} className="text-center text-nowrap">ราคาขาย</th>
                        <th style={{width: 50}} className="text-center text-nowrap">ราคาขายจริง</th>
                        <th style={{width: 50}} className="text-center text-nowrap">ยอดจัด</th>

                        <th style={{width: 50}} className="text-center text-nowrap">ดาวน์</th>
                        <th style={{width: 50}} className="text-center text-nowrap">เงินมัดจำ</th>
                        <th style={{width: 50}} className="text-center text-nowrap">จำนวนเงินจอง</th>
                        <th style={{width: 50}} className="text-center text-nowrap">ผู้รับเงิน</th>

                        <th style={{width: 50}} className="text-center">ชื่อลูกค้า</th>
                        <th style={{width: 50}} className="text-center text-nowrap">เบอร์โทร</th>
                        <th style={{width: 50}} className="text-center text-nowrap">ประกันรถ</th>
                        <th style={{width: 50}} className="text-center text-nowrap">ผู้รับจอง(เซลล์)</th>

                        <th style={{width: 50}} className="text-center text-nowrap">ช่องทางลูกค้า</th>
                        <th style={{width: 50}} className="text-center text-nowrap">สถานะการจอง</th>
                      </tr>
                      </thead>
                      <tbody>
                       {tableRows}
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }

}

const styles = {
  overflowY: {
    overflow: 'scroll',
  }
}

export default CarbookReport
