import React, { Component } from 'react'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import { APIURL, IMAGE_FULL_URL, IMAGE_NOPHOTO_URL } from '../../../config/config'
import axios from 'axios'
import Loading from '../../Loading'
import { Modal, ModalBody } from 'reactstrap'
import PreviewImage from '../../../views/PreviewImage'
import {save} from "save-file";
import {AlertWarning} from "../../Alert/Alert";
import {CarSellUtil, CarsUtil} from "../../../utils/carSellUtil";

class ExpenseCarOther extends Component{
  constructor (props) {
    super(props)
    this.state = {
      date_start: new Date(),
      date_end: new Date(),
      expenseCarOtherList: [],
      sumCarOther: 0,
      isLoading: false,
      preview_image_url: '',
      preview_image_select: false,
      income_expense_item: [],
      income_expense_item_id: ''
    }

    this.handleDateStart = this.handleDateStart.bind(this)
    this.handleDateEnd   = this.handleDateEnd.bind(this)
    this.loadExpenseCarOther   = this.loadExpenseCarOther.bind(this)
    this.togglePreviewImage = this.togglePreviewImage.bind(this)
    this.exportCarExpensesOtherFromDate = this.exportCarExpensesOtherFromDate.bind(this)
    this.loadIncomeExpenseItemData = this.loadIncomeExpenseItemData.bind(this)
    this.handleIncomeExpenseItemChange = this.handleIncomeExpenseItemChange.bind(this)
  }

  componentDidMount() {
    CarsUtil.removeCarSelect()
    CarSellUtil.removeCarSellSelect()

    this.loadExpenseCarOther()
    this.loadIncomeExpenseItemData()
  }

  loadIncomeExpenseItemData () {
    axios.get(APIURL + '/income_expense_item/get/expense')
      .then(res => {
        this.setState({income_expense_item: res.data})
      }).catch(error => {
      this.setState({income_expense_item: []})
    })
  }

  handleDateStart(date){
    this.setState({
      date_start: date
    });
    //  this.loadExpenseCarOther()
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    });
    // this.loadExpenseCarOther()
  }

  loadExpenseCarOther() {

    if(this.state.date_start > this.state.date_end){
      alert('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let income_expense_item_id = this.state.income_expense_item_id

    let date_start =  moment(this.state.date_start).format('YYYY-MM-DD')
    let date_end   =  moment(this.state.date_end).format('YYYY-MM-DD')

    // let url = APIURL+'/expense/car_other/'+date_start+'/'+date_end
    let url = APIURL+'/expense/car_other_type'

    let data = {
      income_expense_item_id: income_expense_item_id,
      date_start: date_start,
      date_end: date_end
    }

    this.setState({
      isLoading: true
    }, () => {

      axios.post(url, data).then(res => {
        if(res.status === 200){
          // car_repair_cost | car_cost_repair
          let sumCarOther = 0
          if(res.data.length > 0) {
            sumCarOther =  res.data.reduce((acc, next) => acc + next.expense_amount, 0)
          }

          let sumCarOtherFix2 = sumCarOther.toFixed(2)
          this.setState({
            expenseCarOtherList: res.data,
            sumCarOther: sumCarOtherFix2,
            isLoading: false
          })
        }
      })

    })
  }

  onPreviewSelected(url){
    this.setState({
      preview_image_url: url,
      preview_image_select: true
    });
  }

  togglePreviewImage() {
    this.setState({
      preview_image_select: !this.state.preview_image_select
    });
  }

  async exportCarExpensesOtherFromDate(){
    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    this.setState({
      isLoading: true
    })

    let income_expense_item_id = this.state.income_expense_item_id === '' ? 'all' : this.state.income_expense_item_id

    axios.get(APIURL + '/reportrepair/carexpensotherfromdate/'+start_date+'/'+end_date+'/'+income_expense_item_id)
        .then( async res => {
          const excelBuffer = res.data.excelBuffer
          const currentDatetime = res.data.currentDatetime
          const fileName = 'รายงานคชจ.อื่นๆของรถตามวันที่เลือก' + ' ' + currentDatetime +'.xlsx'
          this.setState({
            isLoading: false
          })
          await save(excelBuffer, fileName)

        }).catch(error => {
      console.log('error:', error)
      // this.setState({lists: []})
    })
  }

  handleIncomeExpenseItemChange (event) {
    let income_expense_item_id = event.target.value
    // let income_expense_item_name = event.target.options[event.target.selectedIndex].text
    // let that = this ;
    this.setState({
      // expense_name: income_expense_item_name,
      income_expense_item_id: income_expense_item_id
    })
  }

  render () {
    const { expenseCarOtherList } = this.state

    const emptyList = <tr><td colSpan={8} className="text-center text-danger"> ไม่พบข้อมูล </td></tr>
    const expenseCarRepairTR = expenseCarOtherList.map((expense, index) => {
      let image_url = ''
      if(expense.expense_image_file === 'nophoto.png') {
        image_url = IMAGE_NOPHOTO_URL
      } else {
        if (expense.expense_image_file !== null && expense.expense_image_file!== undefined) {
          if (expense.expense_image_file.includes('https')) {
            image_url = expense.expense_image_file
          }else{
            image_url = IMAGE_FULL_URL + expense.expense_image_file
          }
        }else{
          image_url = IMAGE_NOPHOTO_URL
        }
      }

      return (
        <tr key={index}>
          <th className="text-center">{index + 1}</th>
          <th className="text-center" width={120}>
            {moment(expense.expense_date).format('YYYY-MM-DD')}</th>
          <th className="text-left">
           รายการ: {expense.expense_name}
           <br/>
           หมวดค่าใช้จ่าย: {expense.income_expense_item_name}
          </th>
          <th className="text-left" width={200} >
            {expense.car_name}<br/>
            ทะเบียน: {expense.car_license_plate_new}
          </th>
          <th className="text-center">
            <img
              src={image_url}
              style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
              alt="เอกสาร"
              onClick={()=>this.onPreviewSelected(image_url)}
            />
          </th>
          <th className="text-center">
            <NumberFormat value={expense.expense_amount}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'฿'} />
          </th>
          <th className="text-center" width={150}>
            {expense.user_created} <br/>
            เมื่อ {moment(expense.created_at).format('DD-MM-YYYY HH:mm:ss')}
          </th>
        </tr>
      )
    })

    const expenseBody = expenseCarOtherList.length === 0 ? emptyList : expenseCarRepairTR

    let income_expense_item_list = this.state.income_expense_item.map((income_expense_item,index)=>{
      return(
        <option key={index} value={income_expense_item.income_expense_item_id}>{income_expense_item.income_expense_item_name}</option>
      )
    })

    return (
      <div className="card">

        <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
          <ModalBody>
            <PreviewImage
              imageUrl={this.state.preview_image_url}
            />
          </ModalBody>
        </Modal>

        <Loading isLoading={this.state.isLoading} />

        <div className="card-block">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <div className="card card-accent-warning">
                <div className="card-header card-sparepart">
                  <strong className="text-title"> ข้อมูลค่าใช้จ่ายอื่นๆ ของรถ</strong>
                  <button type="button" className="btn btn-sm btn-success float-right" onClick={() => this.exportCarExpensesOtherFromDate()} > Excel คชจ.อื่นๆของรถตามวันที่เลือก </button>
                  {/*<button type="button"
                          onClick={this.toggleExpense}
                          className="btn btn-primary float-right">
                    <i className="icon-plus"></i>&nbsp; เพิ่มข้อมูลค่าใช้จ่าย
                  </button>
                  <Modal isOpen={this.state.toggle_expense} toggle={this.toggleExpense}
                         className={'modal-lg'}>
                    <ModalBody>
                      <ExpenseForm onToggle={this.toggleExpense}/>
                    </ModalBody>
                  </Modal>*/}
                </div>

                <div className="row mt-3 mb-4">
                  <div className="col-md-1 text-right mt-1">
                    จาก
                  </div>
                  <div className="row col-5">
                    <div className="col-5">
                      <DatePicker
                        selected={this.state.date_start}
                        onChange={this.handleDateStart}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                    <div className="col-1 ml-2 mt-1">
                      ถึง
                    </div>

                    <div className="col-5 ml-2">
                      <DatePicker
                        selected={this.state.date_end}
                        onChange={this.handleDateEnd}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="col-2 text-right pt-0">
                    <select
                      className="form-control"
                      name="income_expense_item_id"
                      onChange={this.handleIncomeExpenseItemChange.bind(this)}
                      value={this.state.income_expense_item_id}
                    >
                      <option value=''>เลือกหมวดค่าใช้จ่าย</option>
                      {income_expense_item_list}
                    </select>
                  </div>

                  <div className="col-1 text-right pt-0">

                    <button
                      className="btn btn-sm btn-head-bar"
                      onClick={() => this.loadExpenseCarOther()}
                      style={{
                        height: 36,
                        paddingTop:0,
                        paddingBottom:0,
                        borderRadius:5,
                        display: 'inline',
                        marginTop: '0rem'
                      }}
                    >
                      <i className="icon-search"/>&nbsp; ค้นหา
                    </button>

                  </div>

                  <div className="col-2 text-right pt-2">
                    <h5>
                      {/*รวมค่าใช้จ่ายอื่นๆ ของรถ&nbsp;*/}
                      รวม&nbsp;
                      <NumberFormat
                        displayType={'text'}
                        thousandSeparator={true}
                        value={this.state.sumCarOther}/>
                      &nbsp;บาท
                    </h5>
                  </div>
                </div>

                <table className="table table-striped">
                  <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th className="text-center">วันที่ใช้จ่าย</th>
                    <th className="text-center">รายละเอียด</th>
                    <th className="text-center">ข้อมูลรถ</th>
                    <th className="text-center">ไฟล์เอกสาร</th>
                    <th className="text-center">ยอดจ่าย</th>
                    <th className="text-center">ผู้บันทึก</th>
                  </tr>
                  </thead>
                  <tbody>
                  {expenseBody}
                  </tbody>
                </table>

              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(ExpenseCarOther)
