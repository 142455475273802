import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { APIURL, IMAGE_FULL_URL, IMAGE_NOPHOTO_URL, IMAGE_UPLOAD_ERROR } from '../../config/config'
import axios from 'axios'

class CarRepairFile extends Component{
  constructor (props) {
    super(props);
    // car_repair_file , car_repair_key
    this.state = {
      car_repair_file: '',
      car_repair_key: '',
      car_repair_file2: '',
      car_repair_key2: '',
      is_upload: false
    }

    this.saveCarImage = this.saveCarImage.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);

  }

  componentDidMount () {
    let car_repair = this.props.car_repair
    this.setState({
      car_repair_file: car_repair.car_repair_file === 'nophoto.png' || car_repair.car_repair_file === null  ? IMAGE_NOPHOTO_URL: car_repair.car_repair_file,
      car_repair_key: car_repair.car_repair_key,
      car_repair_file2: car_repair.car_repair_file2 === 'nophoto.png' || car_repair.car_repair_file2 === null ? IMAGE_NOPHOTO_URL: car_repair.car_repair_file2,
      car_repair_key2: car_repair.car_repair_key2,
      is_upload: false
    })
  }

  saveCarImage(e,urlImage){
    e.preventDefault();
    let a = document.createElement('a');
    a.href = urlImage;
    a.target = '_blank';
    a.download = urlImage;
    a.click();
    a.remove();
    return false;
  }

  handleUploadFile (event){
    const car_repair = this.props.car_repair
    const input_file_name = event.target.name
    // let slip_key = input_file_name === 'slip_image1' ? this.state.slip_key1 : this.state.slip_key2
    let car_repair_key = ''

    const that = this;
    const car_repair_id = car_repair.car_repair_id

    // input_file_name= slip_image2
    if (input_file_name === 'file_image1'){
      car_repair_key = this.state.car_repair_key === null ? '' : this.state.car_repair_key
      this.refs.img_file_image1.src='img/loading.gif'
      this.refs.img_file_image1.height=32
    } else if (input_file_name === 'file_image2') {
      car_repair_key = this.state.car_repair_key2 === null ? '' : this.state.car_repair_key2
      this.refs.img_file_image2.src='img/loading.gif'
      this.refs.img_file_image2.height=32
    }

    const data = new FormData();
    data.append('uploads', event.target.files[0], event.target.files[0].name)
    data.append('car_repair_id', car_repair_id)
    data.append('car_repair_key', car_repair_key)
    data.append('car_repair_file', input_file_name)

    // axios.put(APIURL+'/debtors_detail/slip/'+debtors_detail_id, data).then(function(res) {
    axios.put(APIURL+'/car_repair/image/'+car_repair_id, data).then(function(res) {
      const resData = res.data;
      const filename = resData.image;
      const image_key = resData.image_key;
      let image_url = ''

      if(resData.image === undefined) {
        image_url = IMAGE_UPLOAD_ERROR
      } else if (resData.image === 'uploaderror') {
        image_url = IMAGE_UPLOAD_ERROR
      } else {
        image_url = filename
      }

      if( input_file_name === 'file_image1'){
        that.setState({
          car_repair_file: image_url,
          car_repair_key: image_key,
          is_upload: true
        }, () =>  {
          // close && reload data
          // that.props.onToggle()
          // that.props.onReload()
        })
      }else if (input_file_name === 'file_image2'){
        that.setState({
          car_repair_file2: image_url,
          car_repair_key2: image_key,
          is_upload: true
        })
      }
    })

  }

  render () {
    const {
      width,
      car_repair
    } = this.props

    // let car_repair_id = car_repair.car_repair_id

    let image_url = ''

    if(car_repair.car_repair_file === 'nophoto.png') {
      image_url = IMAGE_NOPHOTO_URL
    } else {
      // check https
      if (car_repair.car_repair_file !== null && car_repair.car_repair_file !== undefined) {
        if (car_repair.car_repair_file.includes('https')) {
          image_url = car_repair.car_repair_file
        }else{
          image_url = IMAGE_FULL_URL + car_repair.car_repair_file
        }
      }else{
        image_url = IMAGE_NOPHOTO_URL
      }
    }

    let image_url2 = ''

    if(car_repair.car_repair_file2 === 'nophoto.png') {
      image_url2 = IMAGE_NOPHOTO_URL
    } else {
      // check https
      if (car_repair.car_repair_file2 !== null && car_repair.car_repair_file2 !== undefined) {
        if (car_repair.car_repair_file2.includes('https')) {
          image_url2 = car_repair.car_repair_file2
        }else{
          image_url2 = IMAGE_FULL_URL + car_repair.car_repair_file2
        }
      }else{
        image_url2 = IMAGE_NOPHOTO_URL
      }
    }


    return (
      <Modal isOpen={this.props.toggle} toggle={this.props.onToggle}
             className={'modal-lg '}
             style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
      >
        <ModalHeader toggle={this.props.onToggle}>แนบไฟล์เอกสาร</ModalHeader>
        <ModalBody>
          <div className="row p-5">

            <div className="form-group col-sm-1">
            </div>

            <div className="form-group col-sm-5">
              <label htmlFor="name">1) แนบเอกสาร
                &nbsp;
                <a rel="noopener noreferrer"
                   href={image_url}
                   target="_blank"
                   onClick={(e)=>this.saveCarImage(e, image_url)}
                   className={image_url===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                  <span>donwload</span>
                </a>
              </label>
              <div style={styles.fileInputWrapper} >

                <button style={styles.btnFileInput}>
                  <img src={this.state.car_repair_file}
                       style={styles.btnFileInputImage}
                       ref="img_file_image1"
                       alt="เอกสาร 1"
                  />
                </button>
                <input
                  style={styles.fileInput}
                  type="file"
                  name="file_image1"
                  onChange={this.handleUploadFile}
                />
              </div>
            </div>

            <div className="form-group col-sm-5">
              <label htmlFor="name">2) แนบเอกสาร
                &nbsp;
                <a rel="noopener noreferrer"
                   href={image_url2}
                   target="_blank"
                   onClick={(e)=>this.saveCarImage(e, image_url2)}
                   className={image_url2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                  <span>donwload</span>
                </a>
              </label>
              <div style={styles.fileInputWrapper} >

                <button style={styles.btnFileInput}>
                  <img src={this.state.car_repair_file2}
                       style={styles.btnFileInputImage}
                       ref="img_file_image2"
                       alt="เอกสาร 2"
                  />
                </button>
                <input
                  style={styles.fileInput}
                  type="file"
                  name="file_image2"
                  onChange={this.handleUploadFile}
                />
              </div>
            </div>

          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary"
                  onClick={() => {
                    if(this.state.is_upload) {
                      this.props.onReload()
                    }
                    this.props.onToggle()

                  }}
          >ยกเลิก</Button>
        </ModalFooter>
      </Modal>
    )
  }
}


const styles = {
  bottomLine: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
  },
  fileInputWrapper: {
    position: 'relative',
    overflow: 'hidden',
    height: 180,
    width: 200,
  },
  btnFileInput: {
    height: 180,
    width: 200,
    display: 'inline-block',
    backgroundColor: "#D2DDE2",
    backgroundImage: "linear-gradient(to bottom, #D2DDE2, #FCF8FF)",
    backgroundRepeat: "repeat-x",
    color: "#ffffff",
    textShadow: "0 -1px 0 rgba(0, 0, 0, 0.25)"
  },
  btnFileInputImage:{
    position: 'absolute',
    top: 0,
    left: 0,
    height: 180,
    width: 200,
  },
  fileInput: {
    position: 'absolute',
    top: 0,
    right: 0,
    opacity: 0,
    height: 180,
    width: 200,
    cursor: 'pointer'
  }
}

export default CarRepairFile
