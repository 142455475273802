import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import { Redirect } from 'react-router-dom'
import { IMAGE_THUMB_URL } from '../../../config/config'
// IMAGE_FULL_URL,
import CarLeasingView from '../CarLeasingView'
import * as moment from 'moment'
import 'moment/locale/th'

export default class CarRowReservation extends Component {
  constructor (props) {
    super(props)
    this.state = {
      'btnClick': '',
      'carSelect': 0,
      car_id: '',
      upload_code: '',
      carcalculate: false,
      carLeasingList: [],
      cars: [],
      form_status: '',
      claim_status: '',
    }
    this.toggleModal= this.toggleModal.bind(this);
    this.openClaimAdd= this.openClaimAdd.bind(this);
  }

  toQueryString (paramsObject) {
    return Object
      .keys(paramsObject)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(paramsObject[key])}`)
      .join('&')

  }

  handleOnClick (action, car, car_sell) {

    var search3 = this.toQueryString(car)

    this.setState({
      'btnClick': action,
      'carSelect': search3,
      'car_id': car.car_id,
      'upload_code': car.car_upload_code,
    })

    if (action === 'car-edit-status') {
      this.props.onCarStatusEdit(car)
    } else if (action === 'car-calculate') {
      this.props.onCarCalculate(car)
    } else if (action === 'car-share') {
      //alert(car.car_share)
      this.props.onCarShare(car)
    } else if (action === 'car-delete') {
      this.props.onCarDelete(car)
    } else if (action === 'car-leasing'){
      this.props.onCarLeasing(car)
    } else if (action === 'car-release') {
      this.props.onCarRelease(car)
    }

  }

  componentDidMount() {
    this.setState({
      cars: this.props.cars
    });
  }

  componentWillReceiveProps(nextProps) {

    if(nextProps.carLeasingList.length ===0 && this.props.carLeasingList.length === 0 ) {
      this.setState({carLeasingList: []});
    }else if(nextProps.carLeasingList !== this.props.carLeasingList) { // && this.props.carLeasingList.length === 0
      if (nextProps.carLeasingList.length > 0 ) {
        this.setState({
          carLeasingList: nextProps.carLeasingList
        })
      }else{
        this.setState({carLeasingList: []});
      }
    }

    if(nextProps.cars !== this.props.cars) {
      this.setState({
        cars: nextProps.cars
      });
    }
  }

 /* shouldComponentUpdate(nextProps, nextState) {
    console.log('shouldComponentUpdate CarRow')
    console.log(nextProps.carLeasingList)
    return this.props.carLeasingList !== nextProps.carLeasingList || nextState.input !== this.state.input
  } */

  openClaimAdd() {
    /* start : ออกใบเคลม
    process : กำลังดำเินการซ่อม
    success : ซ่อมเสร็จรอลูกค้ามารับ
    complete : ลูกค้ามารับเรียบร้อย
    this.setState({
      claim_status: 'start',
      form_status: 'add'
    },()=>{
      this.toggleModal();
    });*/

   // this.pro

  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }

  render () {

    var target = '';
    if (this.state.btnClick === 'car-repair') {
       target = '/car-repair/new?car_id=' + this.state.car_id
      return <Redirect push to={target}/>
    } else if (this.state.btnClick === 'car-detail') {
       target = '/cars/detail/?car_id=' + this.state.car_id
      target += '&upload_code=' + this.state.upload_code
      return <Redirect push to={target}/>
    } else if (this.state.btnClick === 'car-buy') {
       target = '/sales/buy/?car_id=' + this.state.car_id
      return <Redirect push to={target}/>
    } else if (this.state.btnClick === 'car-book') {
       target = '/cars/book/?car_id=' + this.state.car_id
      return <Redirect push to={target}/>

    } else if (this.state.btnClick === 'car-book-cancel') {
       target = '/cars/book-cancel/?car_id=' + this.state.car_id
      return <Redirect push to={target}/>
    }

    let tbodyList = this.state.cars.map((car, index) => {

      let rowCount = this.state.cars.length;
      let currentRow = rowCount - index
      let image_url = ''
      if (car.car_upload_filename.includes('https')) {
        image_url = car.car_upload_filename
      } else {
        image_url = IMAGE_THUMB_URL + car.car_upload_filename
      }

      return (
        <tr className="text-center" key={index}>
          <td>{currentRow}</td>
          <td>
            <div className="row">
              <div className="col-md-12 mt-2">
                <img src={image_url} className="img-cars" alt="car"/>
              </div>
            </div>
            <div className="row hidden-md ">
              <div className="col col-sm-12 col-md-12 col-lg-12 mt-2">
                <button type="button" onClick={() => this.handleOnClick('car-calculate', car)}
                        className="btn btn-success btn-sm btn-block ">
                  <i className="icon-calculator"/> ค่างวด
                </button>
              </div>
              <div className="col col-sm-12 col-md-12 col-lg-12 mt-2">
                <button type="button" onClick={() => this.handleOnClick('car-share', car)}
                        className="btn btn-primary btn-sm btn-block ">
                  <i className="icon-share"/> แชร์
                </button>
              </div>
            </div>

          </td>
          <td className="text-center">
            {
              car.car_book_id !== null || car.car_book_id !== undefined ?
                <div>
                  <strong>
                    <span className="text-success">
                      ผู้จอง #{car.car_book_id}: {car.customer_name} {' '} {car.customer_lastname} {'('+car.customer_mobile +')'}
                    </span>
                    <br/>
                    เมื่อ : {moment(car.booking_time).locale('th').format('LLL')}
                  </strong>
                  <hr/>
                </div>
                : null
            }

            <strong>{car.car_name}</strong>
            <br/>
            {
              car.car_license_plate_new === '' ? null : (
                  <>
                    <strong> ทะเบียนรถ : {car.car_license_plate_new}</strong>
                    &nbsp; <strong>จ.{car.province_name_new}</strong>
                  </>
              )
            }

            {
              car.car_license_plate_new !== '' && car.car_license_plate_old !== '' && car.car_license_plate_new !== car.car_license_plate_old ? <br/> : null
            }

            {
              car.car_license_plate_new !== car.car_license_plate_old && car.car_license_plate_old !== '' ? (
                      <>
                        <strong> &nbsp;&nbsp; ทะเบียนเดิม : {car.car_license_plate_old} &nbsp;จ.{car.province_name_old}</strong>
                      </>
                  )
                  : null
            }
            {/*<strong> ทะเบียนรถ : {car.car_license_plate_new}</strong>*/}
            <br/>
            <img src={'img/if_world.png'} style={{width: 16}}
                 className={car.car_share === 'show' ? '' : 'hidden'}
                 alt="share status"
            />
            <br/>
            <center>

            <CarLeasingView
              car={car}
              carLeasingList={this.state.carLeasingList}
            />

            {/*<button type="button"
                    style={{marginTop: 8}}
                    onClick={() => this.handleOnClick('car-leasing', car)}
                    className="btn btn-outline-danger btn-sm btn-block w-50">
              <i className="icon-diamond"></i> ยอดจัด
            </button>*/}

            </center>

          </td>
          {/*<td className={this.props.user_type === 'admin' ? 'text-center' : 'hidden'}>
            <NumberFormat
               value={car.car_cost}
               displayType={'text'}
               thousandSeparator={true}
               prefix={'฿'}
            />
          </td>*/}
          <td>
            <div className={this.props.user_type === 'admin' ? 'text-center' : 'hidden'}>
              ราคาทุน :&nbsp;
            <NumberFormat
              value={car.car_cost}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'฿'}
            />
              <hr/>
            </div>
            <div>
            ราคาขาย :&nbsp;
            <NumberFormat
              value={car.car_sale_price}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'฿'}
            />
              <hr/>
            </div>
            <div className={this.props.user_type === 'admin' ? 'text-center' : 'hidden'}>
              กำไร :&nbsp;
              <NumberFormat
                value={car.car_net_profit}
                displayType={'text'}
                thousandSeparator={true} prefix={'฿'}
              />
              <hr/>
            </div>

          </td>
          {/*<td className={this.props.user_type === 'admin' ? 'text-center' : 'hidden'}>
            <NumberFormat
               value={car.car_net_profit}
               displayType={'text'}
               thousandSeparator={true} prefix={'฿'}
            />
          </td>*/}
          <td>
             <span className="badge"
                   style={{backgroundColor: car.car_status_color}}>{car.car_status_name}
             </span>
            <br/>
            <br/>โดย : {car.booking_fullname}
            <br/>วันที่ : {moment(car.booking_time).locale('th').format('LLL')}
          </td>

          <td>
            <button onClick={() => this.handleOnClick('car-detail', car)} type="button"
                    className="btn btn-info btn-sm btn-block"><i className="icon-list">
              &nbsp;รายละเอียดรถ</i>
            </button>
            <button onClick={() => this.handleOnClick('car-repair', car)} type="button"
                    className="btn btn-danger btn-sm btn-block"><i className="icon-wrench">
              &nbsp;รายการปรับแต่ง</i>
            </button>
            <button onClick={() => this.handleOnClick('car-edit-status', car)} type="button"
                    className={this.props.user_type === 'technician' ? 'hidden' : 'btn btn-warning btn-sm btn-block'}
            ><i className="icon-note">
              &nbsp;แก้ไขสถานะ</i>
            </button>

            <button onClick={() => this.handleOnClick('car-book', car)} type="button"
                    className={this.props.user_type === 'technician' ? 'hidden' : 'btn btn-info btn-sm btn-block'}
            >
              <i className="icon-star"/>
               &nbsp;ดูการจอง/จองเพิ่ม
            </button>

            {/*<button  onClick={()=>this.handleOnClick('car-book-cancel', car)}  type="button" className={(car.car_status_id === 7)? "btn btn-danger btn-sm btn-block":"btn btn-danger btn-sm btn-block hidden"}><i className="icon-close"> จอง/ยกเลิกการจอง</i></button>*/}
            <button onClick={() => this.handleOnClick('car-buy', car)} type="button"
                    className={this.props.user_type === 'technician' ? 'hidden' : 'btn btn-success btn-sm btn-block'}
            >
              <i className="icon-trophy">
              &nbsp;ขายรถคันนี้</i>
            </button>

            <button onClick={() => this.handleOnClick('car-delete', car)} type="button"
                    className={this.props.user_type === 'admin'? 'btn btn-danger btn-sm btn-block': 'hidden'}

            ><i className="icon-trash">
              &nbsp;ลบรถคันนี้</i>
            </button>

            <button
              type="button"
              onClick={() => this.handleOnClick('car-release', car)}
              className="btn btn-sm btn-primary btn-block"
            >
              <i className="icon-plus">&nbsp; ใบปล่อยรถ</i>
            </button>

          </td>
        </tr>
      )
    })

    return (
      <tbody>
      {tbodyList}
      </tbody>
    )
  }
}

