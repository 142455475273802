import React, { Component } from 'react'
import moment from "moment";
import {PAGE20} from "../../../config/config";

export  default class CustomerListRow extends Component{


    render(){

       let customer_all = this.props.customer_all
       let all_page = Math.ceil(customer_all/PAGE20)
       let page_array = Array.from(Array(all_page).keys());

      let tab_pages = page_array.map((page,index)=>{
        let page_tmp = page+1
        return (
          <li className="page-item" key={index}>
            <button className="page-link"  onClick={()=>{  this.props.onLoadDataPage(page_tmp) }}>
              &nbsp;{page_tmp}&nbsp;
            </button>
          </li>
        )
      });

        let customerList = this.props.customer_list.map((customer,index)=>{
            return (
                <tr key={index}>
                    <td className="text-center">{ customer.customer_id }</td>
                    <td className="text-center text-nowrap">{ moment(customer.customer_add_date).locale('th').format('LL')}</td>
                    <td className="text-nowrap">{ customer.customer_name }</td>
                    <td className="text-nowrap">{ customer.customer_lastname }</td>
                    <td className="text-center text-nowrap">{ customer.customer_card_id }</td>
                    <td>{ customer.customer_address }</td>
                    {/*<td className="text-center">{moment( customer.customer_birth ).locale('th').format('LL')}</td>*/}
                    {/*<td className="text-center">{ customer.customer_age }</td>*/}
                    <td className="text-left text-nowrap">
                      โทร: { customer.customer_mobile }<br/>
                      Line: { customer.customer_line }<br/>
                      FB: { customer.customer_facebook }<br/>
                    </td>
                    <td className="text-center">{ customer.employee_name }</td>
                    <td className="text-center">
                      {customer.car_sell_id !== '0' ? <span className="badge bg-success">ลูกค้า</span> : (customer.customer_find_car_id!=='0')?  <span className="badge bg-warning">หารถ</span> : <span className="badge bg-danger">ผู้สนใจ</span>}
                    </td>
                    <td className="text-center">
                        <button className="btn btn-sm btn-warning btn-md-width mr-2 mb-2"
                                style={styles.button}
                                onClick={this.props.customerCallbacks.edit.bind(null,customer)}
                        >
                            <i className="fa fa-edit"/> แก้ไข</button>

                        <button className={customer.car_sell_id !== '0' ? "hidden" : "btn btn-sm btn-danger btn-md-width mb-2 mr-2"}
                                onClick={this.props.customerCallbacks.delete.bind(null,customer)}
                                style={styles.button}
                        >
                            <i className="fa fa-remove"/> ลบ</button>
                    </td>
                </tr>
            )
        });

        return (
        <div className="card-block">
            <table className="table table-striped table-responsive">
                <thead>
                <tr>
                    <th className="text-center">#</th>
                    <th className="text-center text-nowrap">วันที่เพิ่มข้อมูล</th>
                    <th className="text-center">ชื่อ</th>
                    <th className="text-center">นามสกุล</th>
                    <th className="text-center text-nowrap">เลขประจำตัวประชาชน</th>
                    <th className="text-center">ที่อยู่ปัจจุบัน</th>
                    {/*<th className="text-center">ว/ด/ป เกิด</th>*/}
                    {/*<th className="text-center">อายุ</th>*/}
                    <th className="text-center">เบอร์โทรศัพท์</th>
                    <th className="text-center text-nowrap">เซลล์ผู้ดูแล</th>
                    <th className="text-center">สถานะ</th>
                    <th className="text-center">จัดการ</th>
                </tr>
                </thead>
                <tbody>
                    {customerList}
                </tbody>
            </table>

          <ul className= { this.props.is_search ? "hidden" : "pagination d-flex flex-wrap pagination-sm" }>
            {tab_pages}
          </ul>
            {/*<ul className="pagination">
                <li className="page-item"><a className="page-link" href="#">Prev</a></li>
                <li className="page-item active">
                    <a className="page-link" href="#">1</a>
                </li>
                <li className="page-item"><a className="page-link" href="#">2</a></li>
                <li className="page-item"><a className="page-link" href="#">3</a></li>
                <li className="page-item"><a className="page-link" href="#">4</a></li>
                <li className="page-item"><a className="page-link" href="#">Next</a></li>
            </ul>*/}
        </div>
        )
    }
}

const styles = {
    button: {
        borderRadius:5,
        height: 36,
    }
}
