import React, { Component } from 'react'
import moment from "moment";
import Switch from "react-switch";
import {AlertSuccess, AlertWarning} from "../../Alert/Alert";
import axios from 'axios'
import {APIURL} from "../../../config/config";
import userUtil from "../../../utils/userUtil";

export  default class CarModelListRow extends Component{

    constructor (props) {
        super(props)
        this.state = {
            car_model_name: ''
        }
        this.handleOnChange = this.handleOnChange.bind(this)
        this.handleOnSubmitCarModel = this.handleOnSubmitCarModel.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.actionType !== this.props.actionType || prevProps.car_model_name !== this.props.car_model_name ){
            // console.log(this.props.actionType)
            if (this.props.actionType === 'แก้ไข'){
                this.setState({
                    car_model_name : this.props.car_model_name
                })
            }else {
                this.setState({
                    car_model_name : ''
                })
            }
        }
    }

    convertUserTyptToText(type) {
        return userUtil.convertUserTypeToText(type)
    }

    handleOnChange(e) {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        // console.log(name, value)
        this.setState({
            [name]: value
        })
    }

    handleOnSubmitCarModel(event){
        event.preventDefault()
        let car_model_name = event.target.car_model_name.value
        let car_brand_id = this.props.car_brand_id
        let actionType = this.props.actionType

        console.log(car_brand_id)
        if (car_brand_id === undefined){
            AlertWarning('กรุณาเลือกยี่ห้อรถ');
            return
        }

        if(this.props.car_brand_id === 'เลือกยี่ห้อรถ') {
            AlertWarning('กรุณาเลือกยี่ห้อรถ');
            return
        }

        if (car_model_name === ''){
            AlertWarning('กรุณากรอกรุ่นรถ');
            return
        }
        if (actionType === 'บันทึก') {

            event.target.car_model_name.value = ""

            let sendData = {
                car_model_name,
                car_brand_id: car_brand_id
            }

            // console.log('sendData =', sendData)

            axios.post(APIURL + '/car_brand_model', sendData)
                .then(res => {
                    // console.log(res.data)
                    if (res.data === 'success') {
                        AlertSuccess('เพิ่มรุ่นรถเรียบร้อยแล้ว')
                        this.props.onReloadCarModel(car_brand_id)
                        this.setState({
                            car_model_name: ''
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        }else {
            // console.log(actionType)
            // actionType = แก้ไข
            let car_model_update_id = this.props.car_model_id

            axios.put(APIURL + '/car_brand_model/updatemodel', {
                car_brand_id: car_brand_id,
                car_model_id : car_model_update_id,
                car_model_name: car_model_name,
            })
                .then( res => {
                    if(res.status===200) {
                        AlertWarning('แก้ไขรุ่นรถเรียบร้อยแล้ว')
                        this.props.onReloadCarModel(car_brand_id)
                        this.setState({
                            car_model_name: '',
                            //actionType : 'บันทึก'
                        })
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    render(){
        let carModelList;

        if (this.props.car_brand_model_list.length === 0){
            carModelList = <tr><td colSpan={3} className="text-center"><span className="text-danger">ไม่พบข้อมูล</span></td></tr>
        } else {
            carModelList = this.props.car_brand_model_list.map((brand_model, index) => {
                return (
                    <tr key={index}>
                        <td className="text-center">{ index+1 }</td>

                        <td className="text-center">
                          รุ่น :  { brand_model.car_model_name } <br/>
                          สถานะ :  { brand_model.status === 'show'? 'ใช้งาน':'ไม่ใช้งาน'} <br/><br/>
                            <Switch height = {20} width ={48}
                                    onChange={(checked)=>{
                                        this.props.onChangeSwitch(checked, brand_model)
                            }}
                                    checked={brand_model.status === 'show'} />
                        </td>

                        <td className="text-center">
                            <button className="btn btn-sm btn-warning btn-md-width mb-2 mr-2"
                                    style={styles.button}
                                    onClick={() => this.props.onCarModelUpdate(brand_model)}

                            >
                                <i className="fa fa-edit"/> แก้ไข
                            </button>

                            <button onClick={() => this.props.onCarModelSelect(brand_model)}
                                    type="button"
                                    className="btn btn-sm btn-info btn-md-width mb-2 mr-2"
                                    style={styles.button}

                            >
                                <i className="fa fa-check"/> เลือก
                            </button>
                        </td>
                    </tr>
                )
            });
        }

        return (
            <div className="card card-accent-info">
                <div className="card-header">
                    <strong className="text-title">ข้อมูลรุ่นรถ</strong>
                </div>

                <form action="" method="post" onSubmit={this.handleOnSubmitCarModel}>
                    <div className="col-sm-12 mt-3">
                        <label htmlFor="add_car_model">{this.props.actionType === "แก้ไข" ? "แก้ไขรุ่นรถ" : "เพิ่มรุ่นรถ"}</label>

                        <input
                            type="text"
                            id="car_model_name"
                            name="car_model_name"
                            value={this.state.car_model_name}
                            style={styles.inputAdd}
                            className="form-control"
                            placeholder="เพิ่มรุ่นรถใหม่หรือแก้ไข"
                            onChange={this.handleOnChange}
                        />

                        &nbsp;&nbsp;&nbsp;

                        <button type="submit" className={this.props.actionType==="แก้ไข"? "btn btn-warning" : "btn btn-primary"}>
                            <i className={this.props.actionType === "แก้ไข" ? "icon-pencil" : "icon-plus"}/> {this.props.actionType}</button>


                    </div>
                </form>

                <div className="card-block">
                    <table className="table table-striped table-responsive">
                        <thead>
                        <tr>
                            <th className="text-center" style={{width: '8%'}}>ลำดับ</th>
                            <th className="text-center">ชื่อรุ่นรถ</th>
                            <th className="text-center" style={{width: '10%'}}>จัดการ</th>
                        </tr>
                        </thead>
                        <tbody>
                        { carModelList }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const styles = {
    button: {
        borderRadius:5,
        height: 36
    },
    inputAdd: {
        marginLeft: 15,
        width: 200,
        borderRadius:5,
        display: 'inline'
    }
}
