import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames';
import { APIURL } from '../config/config'
import axios from 'axios'
import SettingCarType from './SettingCarType'
import SettingCarStructure from './SettingCarStructure'

class SettingCar extends Component {
  constructor (props) {
    super(props);
    this.state = {
      activeTab: '1',
      user: {} ,
    }

    this.toggle = this.toggle.bind(this);

  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user)
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render () {

    return (
      <div className="col-md-12 mb-4">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              <i className="icon-plus"/> &nbsp;ลักษณะรถ
            </NavLink>
          </NavItem>

          {/*<NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
              <i className="icon-plus"/> &nbsp;ประเภทรถ
            </NavLink>
          </NavItem>*/}

        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <div className="row">
              <div className="col-lg-12">
                  <SettingCarStructure
                    username={this.state.user.user}
                  />
              </div>
            </div>
          </TabPane>

          {/*<TabPane tabId="2">
            <div className="row">
              <div className="col-lg-12">
                <SettingCarType
                  username={this.state.user.user}
                />
              </div>
            </div>
          </TabPane>*/}

        </TabContent>

      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

const styles = {
  button: {
    borderRadius:5,
    height: 36,
  },
  inputSearch: {
    borderRadius:5,
  },
}

export default connect(mapStateToProps)(SettingCar)
