import React, { Component } from 'react'
import AccountForm from '../../../components/Account/AccountForm'
import AccountListRow from '../../../components/Account/AccountListRow'
import axios from 'axios'
import { __DEV__, APIURL } from '../../../config/config'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import {connect} from 'react-redux' ;
import { AlertSuccess } from '../../Alert/Alert'
import AccountBranchPermission from '../AccountBranchPermission'

class AccountList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      lists: [],
      load_data: true,
      danger: false,
      id: 0,
      action: 'บันทึก',
      account: {},
      account_selected: {},
      user_delete: '',
      user: {},
      is_account_branch: 0,
      isOpen: false
    }

    // this.loadBranchData = this.loadBranchData.bind(this)
    this.openBranchPermission = this.openBranchPermission.bind(this);

  }

  componentWillMount () {
    //const queryString = require('query-string');
    let user = JSON.parse(this.props.user);
    this.setState({
      user: user
    })

  }

  async componentDidMount () {
    //if(this.state.load_data)
    await this.loadCompanySetting();
    this.loadData()
  }

  async loadCompanySetting() {
    await axios.get(APIURL + '/company_setting/1').then(res => {
      let data = res.data
      let company_setting = data.company_setting
      // company_setting: company_setting,
      // console.log('is_account_branch=', company_setting.is_account_branch)
      let is_account_branch = company_setting.is_account_branch
      if(__DEV__) {
        is_account_branch = 1
      }

      this.setState({
        is_account_branch: is_account_branch
      })
    })
  }

  loadData () {
    axios.get(APIURL + '/account/')
      .then(res => {
        this.setState({lists: res.data})
      }).catch(error => {
      this.setState({lists: []})
    })
  }

  toggleDanger () {
    this.setState({
      danger: !this.state.danger
    })
  }

  toggleDangerDelete (user_delete) {
    axios.delete(APIURL + '/account/' + user_delete).then(res => {
      if (res.status === 200) {
        this.loadData()
        this.toggleDanger()
        this.setState({action: 'บันทึก'})
      }
    })
  }

  delete (account) {
    this.setState({user_delete: account.user})
    this.toggleDanger()
  }

  editAccount (account) {
    //console.log(account)
    this.setState({action: 'แก้ไข', account: account, id: account.id})
  }

  add (event) {
    event.preventDefault()
    let that = this
    let user = event.target.user.value
    let pass = event.target.pass.value
    let fullname = event.target.fullname.value
    let email = event.target.email.value
    let mobile = event.target.mobile.value
    let address = event.target.address.value
    let type = event.target.type.value
    let branch_id = event.target.branch_id.value

    // console.log('branch_id=', branch_id)
    // return

    if (user === '') {
      alert('กรุณากรอก username')
      return
    }
    if (pass === '') {
      alert('กรุณากรอก password')
      return
    }
    if (fullname === '') {
      alert('กรุณากรอกชื่อ-นามสกุล')
    }
    event.target.user.value = ''
    event.target.pass.value = ''
    event.target.fullname.value = ''
    event.target.email.value = ''
    event.target.mobile.value = ''
    event.target.address.value = ''
    event.target.type.value = ''
    event.target.branch_id.value = ''

    axios.post(APIURL + '/account/', {
      user: user,
      pass: pass,
      fullname: fullname,
      email: email,
      mobile: mobile,
      address: address,
      type: type,
      branch_id: branch_id
    })
      .then(function (response) {

        AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว')

        that.loadData()
        that.setState({account: '', id: 0, action: 'บันทึก'})
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  update (event) {
    event.preventDefault()
    let that = this
    let user = event.target.user.value
    let pass = event.target.pass.value
    let fullname = event.target.fullname.value
    let email = event.target.email.value
    let mobile = event.target.mobile.value
    let address = event.target.address.value
    let type = event.target.type.value
    let branch_id = event.target.branch_id.value

    let id = this.state.id

    if (user === '') {
      return
    }

    event.target.user.value = ''
    event.target.fullname.value = ''
    event.target.pass.value = ''
    event.target.email.value = ''
    event.target.mobile.value = ''
    event.target.address.value = ''
    event.target.type.value = ''
    event.target.branch_id.value = ''

    //alert(user+" id="+id);
    //alert(this.state.user.user)
    //return ;

    axios.put(APIURL + '/account/' + id, {
      user: user,
      pass: pass,
      fullname: fullname,
      email: email,
      mobile: mobile,
      address: address,
      type: type,
      status_field: 'show',
      username: this.state.user.user,
      branch_id
    })
      .then( res => {
        AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว')
        that.loadData()
        that.setState({account: '', id: 0, action: 'บันทึก'})
      })
      .catch(error => {
         //console.log(error)
      })
  }

  /*change (event) {
    //alert (event.target.name);
    let xname = event.target.name
    if (xname === 'fullname') {
      this.setState({
        account: {
          fullname: event.target.value
        }
      })
    } else if (xname === 'email') {
      this.setState({
        account: {
          email: event.target.value
        }
      })
    } else if (xname === 'mobile') {
      this.setState({
        account: {
          mobile: event.target.value
        }
      })
    } else if (xname === 'address') {
      this.setState({
        account: {
          address: event.target.value
        }
      })
    } else if(xname === 'type') {
      this.setState({
        account: {
          type: event.target.value
        }
      })
    }

    //
  } */

  openBranchPermission(account, isOpen) {
    this.setState({
      account_selected: account,
      isOpen: isOpen
    })
  }

  render () {
    return (
      <div className="card">
        <div className="card-block">
          <div className="row">

            <div className="col-sm-8">
              <AccountListRow
                branch_list={this.state.lists}
                isAccountBranch={this.state.is_account_branch}
                branchsCallbacks={
                  {
                    delete: this.delete.bind(this),
                    edit: this.editAccount.bind(this)
                  }
                }
                openBranchPermission={this.openBranchPermission}
              />
            </div>

            <div className="col-sm-4">
              <AccountForm
                onSubmit={
                  {
                    add: this.add.bind(this),
                    update: this.update.bind(this),
                  }
                }

                actionType={this.state.action}
                account={this.state.account}
              />
            </div>
          </div>

          <Modal isOpen={this.state.danger} toggle={this.toggleDanger.bind(this)}
                 className={'modal-danger '}>
            <ModalHeader toggle={this.toggleDanger.bind(this)}>ลบข้อมูล</ModalHeader>
            <ModalBody>
              <strong> คุณต้องการลบข้อมูล user={this.state.user_delete} ใช่หรือไม่ ?? </strong>
              <br />(ข้อมูลจะไม่สามารถกู้คืนได้)
            </ModalBody>

            <ModalFooter>
              <Button color="primary"
                      onClick={this.toggleDangerDelete.bind(this, this.state.user_delete)}>ตกลง</Button>{' '}
              <Button color="secondary" onClick={this.toggleDanger.bind(this)}>ยกเลิก</Button>
            </ModalFooter>
          </Modal>

          {
            this.state.isOpen &&
            <AccountBranchPermission
              account={this.state.account_selected}
              isOpen={this.state.isOpen}
              toggleModal={() => {
                this.setState({
                  isOpen: !this.state.isOpen,
                  account_selected: {}
                })
              }}
            />
          }


        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(AccountList);
