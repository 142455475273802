import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import {connect} from 'react-redux';
import 'moment/locale/th';
import BranchList from "./Branch/BranchList";
import EmployeeList from "./Employee/EmployeeList";
import BankList from "./Bank/BankList";
import MaintenanceCenterList from "./MaintenanceCenter/MaintenanceCenterList";
import AccountList from "./Account/AccountList";
import CompanySetting from './CompanySetting';
import CarModelList from "./CarModel/CarModelList";
import { PORT } from '../config/config'
import SettingCar from './SettingCar'
import {CarSellUtil, CarsUtil} from "../utils/carSellUtil";

// const PORT = window.env.PORT;

class Setting extends Component{
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            activeTab: '1',
            width: 0,
        };

        this.toggle = this.toggle.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    componentDidMount() {
        CarsUtil.removeCarSelect()
        CarSellUtil.removeCarSellSelect()

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    render(){

        // let width = this.state.width

        return(
            <div className="col-md-12 mb-4">

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggle('1'); }}
                        >
                            <i className="icon-options-vertical"/> ข้อมูลทั่วไป
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.toggle('2'); }}
                        >
                            <i className="icon-badge"/> ตั้งค่าสาขา
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '3' })}
                            onClick={() => { this.toggle('3'); }}
                        >
                            <i className="icon-user"/> ตั้งค่าพนักงาน
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '4' })}
                            onClick={() => { this.toggle('4'); }}
                        >
                            <i className="icon-organization"/> ตั้งค่าธนาคาร
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '8' })}
                          onClick={() => { this.toggle('8'); }}
                        >
                            <i className="icon-target"/> ตั้งค่าข้อมูลรถ
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '5' })}
                            onClick={() => { this.toggle('5'); }}
                        >
                            <i className="icon-wrench"/> ตั้งค่าศูนย์ซ่อมบำรุง
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '7' })}
                            onClick={() => { this.toggle('7'); }}
                        >
                            <i className="icon-grid"/> ตั้งค่ารุ่นรถ / รุ่นย่อย
                        </NavLink>
                    </NavItem>

                    {
                        this.props.user_type === 'admin' && PORT === 9010 && this.props.user !== 'admin' ? null :
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '6' })}
                                    onClick={() => { this.toggle('6'); }}
                                >
                                    <i className="icon-shield"/> ตั้งค่าผู้ใช้งาน
                                </NavLink>
                            </NavItem>
                    }

                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <div className="row">
                            <div className="col-lg-12">
                                <CompanySetting />
                            </div>
                        </div>
                    </TabPane>

                    <TabPane tabId="2">
                        <div className="row">
                            <div className="col-lg-12">
                                <BranchList/>
                            </div>
                        </div>
                    </TabPane>

                    <TabPane tabId="3">
                        <div className="row">
                            <div className="col-lg-12">
                                <EmployeeList />
                            </div>
                        </div>
                    </TabPane>

                    <TabPane tabId="4">
                        <div className="row">
                            <div className="col-lg-12">
                                <BankList/>
                            </div>
                        </div>
                    </TabPane>

                    <TabPane tabId="5">
                        <div className="row">
                            <div className="col-lg-12">
                                <MaintenanceCenterList/>
                            </div>
                        </div>
                    </TabPane>

                    <TabPane tabId="6">
                        <div className="row">
                            <div className="col-lg-12">
                                <AccountList/>
                            </div>
                        </div>
                    </TabPane>

                    <TabPane tabId="7">
                        <div className="row">
                            <div className="col-lg-12">
                                <CarModelList/>
                            </div>
                        </div>
                    </TabPane>

                    <TabPane tabId="8">
                        <div className="row">
                            <div className="col-lg-12">
                                {
                                    this.state.activeTab === '8' && <SettingCar />
                                }
                            </div>
                        </div>
                    </TabPane>

                </TabContent>
            </div>
        )
    }

}


// const styles = {
//     bottomLine: {
//         borderBottomColor: '#ccc',
//         borderBottomStyle: 'solid',
//         borderWidth: 0.5,
//     },
//     toUpper: {
//         textTransform: 'uppercase',
//     }
// }

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(Setting);
