import React, { Component } from 'react'
import {connect} from "react-redux";
import MaskedInput from "react-maskedinput";
import {ThaiDatePicker} from "thaidatepicker-react";
import DatePicker from "react-datepicker";
import moment from "moment";
import axios from "axios";
import {APIURL} from "../../../config/config";
import {AlertSuccess} from "../../Alert/Alert";
moment.locale('th');



class CustomerStoreFrontForm extends Component{
    constructor (props) {
        super(props)

        this.state = {
            lists: [],
            load_data: true,
            danger: false,
            customer_id:0,
            action: "บันทึก",
            customer: '',
            startDateWalkIn: new Date(),
            customer_type : 'walkin',
            walkin_level:'',
            walkin_note: ''

        }

        this.handleWalkInDate = this.handleWalkInDate.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnSubmitCustomerStoreFront = this.handleOnSubmitCustomerStoreFront.bind(this);
    }

    handleOnChange(e) {
        let value = e.target.value;
        let name = e.target.name;

        this.setState({
            [name]: value
        })
    }

    handleWalkInDate(date){
        this.setState({startDateWalkIn: new Date(date)});
    }

    handleOnSubmitCustomerStoreFront(event){
        event.preventDefault()
        let username = this.props.username
        let that = this ;

        let customer_name = event.target.customer_name.value
        let customer_lastname = event.target.customer_lastname.value
        let customer_card_id = event.target.customer_card_id.value
        let customer_mobile = event.target.customer_mobile.value

        let walkin_date = moment(this.state.startDateWalkIn).format('YYYY-MM-DD')
        let walkin_level = event.target.walkin_level.value
        let walkin_note = event.target.walkin_note.value
        let customer_type = 'walkin'

        let data = {
            customer_name: customer_name,
            customer_lastname: customer_lastname,
            customer_card_id : customer_card_id,
            customer_mobile: customer_mobile,
            walkin_date: walkin_date,
            walkin_level: walkin_level,
            walkin_note: walkin_note,
            customer_type: customer_type,
        }
        //console.log('data =', data)
        //return;

        axios.post(APIURL + '/customer/', data)
            .then(function (response) {
                AlertSuccess('บันทึกข้อมูลลูกค้าหน้าร้านเรียบร้อย');
                //alert(response.data)
                //that.props.history.push('/customer/list');
                let customer = response.data
                that.props.onToggle(customer);
                //that.setState({ customer: '', customer_id: 0, action: "บันทึก"})
            })
            .catch(function (error) {
                //alert("error:"+error)
            })
    }

    componentDidMount() {
        this.setState({
            customer: this.props.customer
        })
    }

    render() {

        return(
            <div>
                <div className="row">
                    <div className="col-lg-12">
                        {/*ค้นหาชื่อลูกค้าหน้าร้าน / เพิ่มลูกค้า*/}
                        <form action="" method="post" onSubmit={this.handleOnSubmitCustomerStoreFront}>
                            <div className="card">
                                <div className="card-header">
                                    <strong className="text-title">ข้อมูลลูกค้าหน้าร้าน</strong>
                                </div>
                                <div className="card-block">
                                    <div className="row">
                                        <div className="col-lg-12">

                                            <div className="row">
                                                <div className="form-group col-sm-4">
                                                    <label htmlFor="walkin_date">วันที่เข้ามาติดต่อ </label>
                                                    <br/>
                                                    <DatePicker
                                                        type="text"
                                                        className="form-control col-sm-12"
                                                        id="walkin_date"
                                                        name="walkin_date"
                                                        placeholder=""
                                                        selected={this.state.startDateWalkIn}
                                                        dateFormat="yyyy-MM-dd"
                                                        onChange={this.handleWalkInDate}
                                                    />
                                                </div>

                                                <div className="form-group col-sm-4">
                                                    <label htmlFor="customer_name">ชื่อ</label>
                                                    <input type="text"
                                                           className="form-control"
                                                           id="customer_name"
                                                           name="customer_name"
                                                           value={this.state.customer_name || ''}
                                                           onChange={this.handleOnChange}
                                                           placeholder=""
                                                    />
                                                </div>

                                                <div className="form-group col-sm-4">
                                                    <label htmlFor="customer_lastname">นามสกุล</label>
                                                    <input type="text"
                                                           className="form-control"
                                                           id="customer_lastname"
                                                           name="customer_lastname"
                                                           value={this.state.customer_lastname || ''}
                                                           onChange={this.handleOnChange}
                                                           placeholder=""
                                                    />
                                                </div>

                                                <div className="form-group col-sm-4">
                                                    <label htmlFor="customer_card_id">เลขบัตรประชาชน</label>
                                                    <MaskedInput
                                                        mask="1-1111-11111-11-1"
                                                        className="form-control"
                                                        id="customer_card_id"
                                                        name="customer_card_id"
                                                        value={this.state.customer_card_id || ''}
                                                        onChange={this.handleOnChange}
                                                        maxLength="20"
                                                        placeholder=""
                                                    />
                                                </div>

                                                <div className="form-group col-sm-4">
                                                    <label htmlFor="customer_mobile">เบอร์โทรศัพท์</label>

                                                    <MaskedInput
                                                        mask="111-111-1111"
                                                        className="form-control"
                                                        id="customer_mobile"
                                                        name="customer_mobile"
                                                        value={this.state.customer_mobile || ''}
                                                        onChange={this.handleOnChange}
                                                        placeholder=""
                                                    />
                                                </div>

                                                <div className="form-group col-sm-4">
                                                    <label htmlFor="walkin_level">ระดับความต้องการของลูกค้า</label>
                                                    <input type="text"
                                                           className="form-control"
                                                           id="walkin_level"
                                                           name="walkin_level"
                                                           value={this.state.walkin_level || ''}
                                                           onChange={this.handleOnChange}
                                                           placeholder=""
                                                    />
                                                </div>

                                                <div className="form-group col-sm-12">
                                                    <label htmlFor="walkin_note">หมายเหตุ</label>
                                                    <input type="text"
                                                           className="form-control"
                                                           id="walkin_note"
                                                           placeholder=""
                                                    />
                                                </div>

                                                <div className="col-sm-12"><hr/></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 text-center">
                                    <div className="card-block">
                                        <button type="submit" className="btn btn-success" style={styles.buttonHeight}>
                                            <i className="icon-check"/> &nbsp;บันทึกข้อมูลลูกค้าหน้าร้าน</button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        )

    }

}



const styles = {
    inputSearch: {
        marginLeft: 20,
        width: 300,
        display: 'inline'
    },

    buttonHeight: {
        height: 36,
        paddingTop:0,
        paddingBottom:0,
        display: 'inline',
        marginTop: '-0.35rem',
        borderRadius:5,
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CustomerStoreFrontForm);