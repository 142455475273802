import React, { Component } from 'react'
import axios from 'axios'
import {APIURL, IMAGE_UPLOAD_ERROR, PORT} from '../../../config/config'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { connect } from 'react-redux'
import ExpenseForm from '../ExpenseForm/ExpenseForm'
import ExpenseListRow from '../ExpenseListRow/ExpenseListRow'
import DatePicker from 'react-datepicker'
import NumberFormat from "react-number-format"
import moment from 'moment'
import Swal from 'sweetalert2'
import { AlertSuccess, AlertWarning } from '../../Alert/Alert'
import ExpenseEdit from '../ExpenseEdit'
import {save} from "save-file";
import {CarSellUtil, CarsUtil} from "../../../utils/carSellUtil";
import Loading from '../../Loading'

class ExpenseList extends Component {

  constructor (props) {
    super(props)
    this.state = {
      lists: [],
      load_data: true,
      danger: false,
      expense: '',
      toggle_expense: false,
      expense_id: 0,
      user: {},
      w_expense_image_file: false,
      month_year_list: [],
      month_list_thai: '',
      sum_expense: 0,
      date_start: new Date(),
      date_end: new Date(),
      width: 0,
      height: 0,
      expense_selected: null,
      toggle_file_modal: false,
      toggle_edit_modal: false,
      expense_image_file: '',
      expense_image_key: '',
      income_expense_item: [],
      income_expense_item_id: '',
      income_expense_item_name: '',
      is_load: false,
      sum_vat:0,
      is_date_fill: 0,
      company_setting: null,
      isLoading: false
    }

    this.toggleExpense = this.toggleExpense.bind(this);
    this.onDeleteHandle = this.onDeleteHandle.bind(this);
    this.loadExpenseData = this.loadExpenseData.bind(this);
    this.loadExpenseDataMonth = this.loadExpenseDataMonth.bind(this);
    this.handleDateStart = this.handleDateStart.bind(this);
    this.handleDateEnd = this.handleDateEnd.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.onSaveFileModal = this.onSaveFileModal.bind(this);
    this.onEditExpenseModal = this.onEditExpenseModal.bind(this);
    this.toggleFileModal = this.toggleFileModal.bind(this);
    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.loadIncomeExpenseItemData = this.loadIncomeExpenseItemData.bind(this);
    this.handleIncomeExpenseItemChange = this.handleIncomeExpenseItemChange.bind(this);
    this.exportExpenseTentOther = this.exportExpenseTentOther.bind(this);
    this.exportExpenseTentTotal = this.exportExpenseTentTotal.bind(this);
    this.loadCompanySetting = this.loadCompanySetting.bind(this);
    // this.loadExpenseDataWithType = this.loadExpenseDataWithType.bind(this);
  }

  async loadCompanySetting() {
    await axios.get(APIURL + '/company_setting/1').then(res => {
      let data = res.data
      let company_setting = data.company_setting
      const is_date_fill = company_setting.is_date_fill;
      this.setState({
        company_setting: company_setting,
        is_date_fill: is_date_fill
      })
    })
  }

  loadExpenseData () {
    this.loadExpenseDataMonth();
  }

  handleDateStart(date){
    this.setState({
      date_start: date
    }, () => {
      // this.loadExpenseDataMonth()
    })
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    }, () => {
      // this.loadExpenseDataMonth()
    })
  }

  loadExpenseDataMonth () {
    // router.get('/month/:type/:start_date/:end_date',function(req,res,next){
    // const { start_date, end_date } = this.state

    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')
    let income_expense_item_id = this.state.income_expense_item_id === '' ? 'all' :  this.state.income_expense_item_id

    axios.get(APIURL + '/expense/month/2/'+start_date+'/'+end_date+'/'+income_expense_item_id) //2 = ค่าใช้จ่ายของเต๊นท์
      .then(res => {
        let sum_expense = 0
        let sum_vat = 0
        if(res.data.length > 0) {
          sum_expense =  res.data.reduce((acc, expense) => {
            if(expense.include_vat === 1) {
              // ยอด expense_amount รวม vat แล้ว
              return acc + expense.expense_amount
            } else {
              // expense_amount ยังไม่รวม vat
              return acc + expense.expense_amount + Number(expense.expense_amount_vat)
            }


          }, 0)
          sum_vat =  res.data.reduce((acc, next) => acc + next.expense_amount_vat, 0)
        }

        let sum_expense_fix2 = parseFloat(String(sum_expense)).toFixed(2)
        let sum_vat_fix2 = parseFloat(String(sum_vat)).toFixed(2)
        let lists = res.data
        // console.log(lists)
        this.setState({lists: lists, sum_expense : sum_expense_fix2, sum_vat : sum_vat_fix2, is_load: true});
      }).catch(error => {
      this.setState({lists: [], is_load: false})
    })
  }

  // loadExpenseDataWithType () {
  //   // router.get('/month/:type/:start_date/:end_date',function(req,res,next){
  //   // const { start_date, end_date } = this.state
  //
  //   if(this.state.date_start > this.state.date_end){
  //     AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
  //     return
  //   }
  //
  //   let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
  //   let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')
  //
  //   axios.get(APIURL + '/expense/month/2/'+start_date+'/'+end_date) //2 = ค่าใช้จ่ายของเต๊นท์
  //     .then(res => {
  //       let sum_expense = 0
  //       if(res.data.length > 0) {
  //         sum_expense =  res.data.reduce((acc, next) => acc + next.expense_amount, 0)
  //       }
  //
  //       this.setState({lists: res.data, sum_expense: sum_expense})
  //     }).catch(error => {
  //     this.setState({lists: []})
  //   })
  // }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user)
    })
  }

  async componentDidMount () {
    CarsUtil.removeCarSelect()
    CarSellUtil.removeCarSellSelect()
    // if(this.state.load_data)
    // this.loadExpenseData()
    // this.loadExpenseListMonth()
    await this.loadCompanySetting();
    this.loadExpenseDataMonth();
    this.loadIncomeExpenseItemData();
    this.updateWindowDimensions();

    window.addEventListener('resize', this.updateWindowDimensions);
  }

  loadIncomeExpenseItemData () {
    axios.get(APIURL + '/income_expense_item/get/expense')
      .then(res => {
        this.setState({income_expense_item: res.data})
      }).catch(error => {
      this.setState({income_expense_item: []})
    })
  }

  handleIncomeExpenseItemChange (event) {
    // var income_expense_item_id = event.target.value
    let income_expense_item_id = event.target.value
    let income_expense_item_name = event.target.options[event.target.selectedIndex].text

    // console.log('income_expense_item_id=', income_expense_item_id)
    // console.log('income_expense_item_name=', income_expense_item_name)
    this.setState({
      income_expense_item_id: income_expense_item_id,
      income_expense_item_name: income_expense_item_name,
      is_load: false
    })
  }

  toggleExpense () {
    this.setState({
      toggle_expense: !this.state.toggle_expense
    })
    this.loadExpenseData()
  }

  onDeleteHandle(expense) {

    let expense_id = expense.expense_id
    let expense_image_file = expense.expense_image_file
    let expense_image_key = expense.expense_image_key
    let data = {
      expense_image_file: expense_image_file,
      expense_image_key: expense_image_key,
    }

    Swal.fire({
      title: 'ยืนยันการลบ ' + expense.expense_name + ' ',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        // expense_id = 2 => ค่าใช้จ่ายของเต๊นท์
        axios.put(APIURL + '/expense/' + expense_id, data)
          .then(res => {
            if (res.status === 200) {
              this.loadExpenseData()
            }
          }).catch(error => {
          console.log('error:', error)
        })
      }
    });

  }

  expenseMonthYearChange(e) {
    this.loadExpenseTotal(e.target.value)
  }

  onSaveFileModal(expense) {
    // show modal for save update file
    this.setState({
      expense_selected: expense,
      toggle_file_modal: true,
      expense_image_file: expense.expense_image_file,
      expense_image_key: expense.expense_image_key,
    })

  }

  onEditExpenseModal(expense) {
    // show modal for edit
    this.setState({
      expense_selected: expense,
      toggle_edit_modal: true
    })
  }

  toggleFileModal() {
    this.setState({
      toggle_file_modal: !this.state.toggle_file_modal
    })
  }

  toggleEditModal() {
    this.setState({
      toggle_edit_modal: !this.state.toggle_edit_modal
    })
  }

  handleUploadFile(event) {

    let expense_selected = this.state.expense_selected
    let expense_image_key = this.state.expense_image_key
    let expense_id = expense_selected.expense_id

    let input_file_name = event.target.name

    this.refs.img_expense_image_file.src='img/loading.gif'

    const data = new FormData();
    data.append('uploads', event.target.files[0], event.target.files[0].name)
    data.append('expense_id', expense_id)
    data.append('expense_image_key', expense_image_key)
    data.append('expense_image_file', input_file_name)

    axios.put(APIURL+'/expense/file/'+expense_id, data).then((res) => {
      const resData = res.data;
      const filename = resData.image;
      const expense_image_key = resData.expense_image_key;
      let image_url = ''

      if(resData.image === undefined) {
        image_url = IMAGE_UPLOAD_ERROR
      } else if (resData.image === 'uploaderror') {
        image_url = IMAGE_UPLOAD_ERROR
      } else {
        image_url = filename
      }

      AlertSuccess('เพิ่มไฟล์เอกสารเรียบร้อยแล้ว');

      this.setState({
        expense_image_file: image_url,
        expense_image_key: expense_image_key,
        toggle_file_modal: false,
      }, () => this.loadExpenseDataMonth())

    })

  }

  exportExpenseTentOther(){
    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')
    let income_expense_item_id = this.state.income_expense_item_id === '' ? 'all' : this.state.income_expense_item_id

    this.setState({
      isLoading: true
    })

    axios.get(APIURL + '/reportrepair/expensetentother/'+start_date+'/'+end_date+'/'+income_expense_item_id)
        .then( async res => {
          const excelBuffer = res.data.excelBuffer
          const currentDatetime = res.data.currentDatetime
          const fileName = 'รายงานค่าใช้จ่ายอื่นๆของเต็นท์'+currentDatetime+'.xlsx'
          this.setState({
            isLoading: false
          })
          await save(excelBuffer, fileName)

        }).catch(error => {
      console.log('error:', error)
      // this.setState({lists: []})
    })
  }

  exportExpenseTentTotal(){
    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')
    let income_expense_item_id = this.state.income_expense_item_id === '' ? 'all' : this.state.income_expense_item_id

    this.setState({
      isLoading: true
    })

    let url = APIURL + '/reportrepair/expensetotal/'+start_date+'/'+end_date+'/'+income_expense_item_id
    if (PORT === 9043) {
      url = APIURL + '/reportrepair/expensetotalat/'+start_date+'/'+end_date+'/'+income_expense_item_id
    }

    axios.get(url)
        .then( async res => {
          const excelBuffer = res.data.excelBuffer
          const currentDatetime = res.data.currentDatetime
          const fileName = 'รายงานค่าใช้จ่ายรวม_'+currentDatetime+'.xlsx'

          setTimeout(()=> {
            this.setState({
              isLoading: false
            })
          }, 2000);

          await save(excelBuffer, fileName)

        }).catch(error => {
      console.log('error:', error)
      // this.setState({lists: []})
    })
  }

  render () {

    // let width = this.state.width

    const {
      width,
      is_load,
      income_expense_item_name
    } = this.state

    // let month_year_list_option = this.state.month_year_list.map((month_year, index) => (
    //   <option key={index} value={month_year.month_list}>{month_year.month_list_thai}</option>
    // ))

    let income_expense_item_list = this.state.income_expense_item.map((income_expense_item,index)=>{
      return(
        <option key={index} value={income_expense_item.income_expense_item_id}>{income_expense_item.income_expense_item_name}</option>
      )
    })


    return (
      <div className="card">

        <Loading isLoading={this.state.isLoading} />

        <div className="card-block">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <div className="card card-accent-warning">
                <div className="card-header card-sparepart">
                  <strong className="text-title"> ข้อมูลค่าใช้จ่ายอื่นๆของเต็นท์</strong>

                  <button type="button"
                          onClick={this.toggleExpense}
                          className="btn btn-info float-right">
                    <i className="icon-plus"/>&nbsp; เพิ่มข้อมูลค่าใช้จ่าย
                  </button>

                  <Modal isOpen={this.state.toggle_expense}
                         toggle={this.toggleExpense}
                         className={'modal-lg'}
                         style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
                  >
                    <ModalBody>
                      <ExpenseForm
                        onToggle={this.toggleExpense}
                        company_setting={this.state.company_setting}
                        isDateFill={this.state.is_date_fill}
                      />
                    </ModalBody>
                  </Modal>


                  <Modal isOpen={this.state.toggle_edit_modal}
                         toggle={this.toggleEditModal}
                         className={'modal-lg'}
                         style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
                  >
                    <ModalBody>
                      <ExpenseEdit
                        onToggle={this.toggleEditModal}
                        expense= {this.state.expense_selected}
                        company_setting={this.state.company_setting}
                        isDateFill={this.state.is_date_fill}
                        onReload={() => {
                          this.toggleEditModal();
                          this.loadExpenseDataMonth();
                        }}
                      />
                    </ModalBody>
                  </Modal>

                  <Modal className="modal-lg modal-dialog" isOpen={this.state.toggle_file_modal} toggle={this.toggleFileModal}>
                    <ModalHeader toggle={this.toggleFileModal}>แนบไฟล์เอกสาร</ModalHeader>
                    <ModalBody>
                      <div className="row p-5">
                        <div className="form-group col-sm-1">
                        </div>

                        <div className="form-group col-sm-5">
                          <label htmlFor="name">1) เอกสาร 1
                            &nbsp;
                            {/*<a rel="noopener noreferrer"
                               href={this.state.slip_image1}
                               target="_blank"
                               onClick={(e)=>this.saveCarImage(e,this.state.slip_image1)}
                               className={this.state.slip_image1===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                              <span>donwload</span>
                            </a>*/}
                          </label>
                          <div style={styles.fileInputWrapper} >

                            <button style={styles.btnFileInput}>
                              <img src={this.state.expense_image_file}
                                   style={styles.btnFileInputImage}
                                   ref="img_expense_image_file"
                                   alt="เอกสาร 1"
                              />
                            </button>
                            <input
                              style={styles.fileInput}
                              type="file"
                              name="expense_image_file"
                              onChange={this.handleUploadFile}
                            />
                          </div>

                        </div>

                      </div>
                    </ModalBody>
                  </Modal>


                </div>

                {/*<div className="row mt-1">
                  <div className="col-md-1 text-right">
                    เลือกเดือน
                  </div>
                  <div className="col-3">
                    <select
                      className="form-control"
                      name="expense_month_year"
                      onChange={(e) => this.expenseMonthYearChange(e)}
                    >
                      <option value="all">ทั้งหมด</option>
                      {month_year_list_option}
                    </select>
                  </div>
                  <div className="col-7 text-right pt-2">
                    <h5>
                      รวมค่าใช้จ่าย{this.state.month_list_thai === 'all'? 'ทั้งหมด ': ' '+this.state.month_list_thai+' = ' }
                      {this.state.sum_expense.toLocaleString()} บาท
                    </h5>
                  </div>
                  <div className="col-1 text-right pt-2">
                    &nbsp;
                  </div>
                </div>*/}

                <div className="row mt-1">
                  <div className="col-md-1 text-right mt-1">
                    จาก
                  </div>
                    <div className="col-2">
                      <DatePicker
                        selected={this.state.date_start}
                        onChange={this.handleDateStart}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                <div className="mt-1 text-right">
                  ถึง
                </div>

                    <div className="col-2">
                      <DatePicker
                        selected={this.state.date_end}
                        onChange={this.handleDateEnd}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        placeholder=""
                      />
                    </div>

                    <div className="form-group col-3 ">
                      {/*<label htmlFor="income_expense_item">หมวดค่าใช้จ่าย</label>*/}
                      <select
                        className="form-control"
                        name="income_expense_item_id"
                        onChange={this.handleIncomeExpenseItemChange.bind(this)}
                      >
                        <option value="">เลือกหมวดค่าใช้จ่าย</option>
                        {income_expense_item_list}
                      </select>

                    </div>
                    <div className="d-flex mr-2">
                      <button type="button"
                              className="btn btn-primary"
                              onClick={() => this.loadExpenseDataMonth()}
                      > ตกลง </button>
                    </div>
                </div>

                <div className="row mt-2">
                  <div className="col-12 text-right mr-4">
                    <h5>
                      ค่าใช้จ่ายของเต็นท์รวม VAT
                      {
                        income_expense_item_name !== '' && is_load === true ? '('+income_expense_item_name+')' : null
                      }
                      &nbsp;
                      <NumberFormat
                        displayType={'text'}
                        thousandSeparator={true}
                        value={this.state.sum_expense}/>
                      &nbsp;บาท
                    </h5>

                    <h5>
                      VAT ค่าใช้จ่ายของเต็นท์
                      {
                        income_expense_item_name !== '' && is_load === true ? '('+income_expense_item_name+')' : null
                      }
                      &nbsp;
                      <NumberFormat
                          displayType={'text'}
                          thousandSeparator={true}
                          value={this.state.sum_vat}/>
                      &nbsp;บาท
                    </h5>

                    <button type="button"
                            onClick={() => this.exportExpenseTentTotal()}
                            className="btn btn-success float-right ml-2 mt-2">
                      Excel ค่าใช้จ่ายรวม
                    </button>

                    <button type="button"
                            onClick={() => this.exportExpenseTentOther()}
                            className="btn btn-success float-right ml-2 mt-2">
                      Excel ของเต็นท์
                    </button>
                  </div>
                </div>


                <ExpenseListRow
                  expense_list={this.state.lists}
                  onDeleteHandle={this.onDeleteHandle}
                  onSaveFileModal={this.onSaveFileModal}
                  onEditExpenseModal={this.onEditExpenseModal}
                />

              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  fileInputWrapper: {
    position: 'relative',
    overflow: 'hidden',
    height: 180,
    width: 200,
  },
  btnFileInput: {
    height: 180,
    width: 200,
    display: 'inline-block',
    backgroundColor: "#D2DDE2",
    backgroundImage: "linear-gradient(to bottom, #D2DDE2, #FCF8FF)",
    backgroundRepeat: "repeat-x",
    color: "#ffffff",
    textShadow: "0 -1px 0 rgba(0, 0, 0, 0.25)"
  },
  btnFileInputImage:{
    position: 'absolute',
    top: 0,
    left: 0,
    height: 180,
    width: 200,
  },
  fileInput: {
    position: 'absolute',
    top: 0,
    right: 0,
    opacity: 0,
    height: 180,
    width: 200,
    cursor: 'pointer'
  },
  overflowY: {
    overflow: 'scroll',
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(ExpenseList)
