import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import SellReport from './SellReport'
import TradingReport from './TradingReport'
import TaxInvoiceReport from "./TaxInvoiceReport";
import SellSummaryReport from "./SellSummaryReport";
import {PORT} from "../../config/config";

// import SellEmployeeReport from "./SellEmployeeReport";

class ReportTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      activeTab: this.props.type === 'admin' ? '1' : '3',
    }

    this.toggle = this.toggle.bind(this);

  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user),
    });
  }

  componentDidMount() {
    // {this.state.user.user}
    if(PORT === 9043) {
      this.setState({
        activeTab: '4'
      })
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  // render() {
  //   return(
  //     <div className="col-md-12">
  //       xxx
  //     <div/>
  //   )
  // }

  render () {
    return(
      <div className="col-md-12">
        <Nav tabs>
          {
            this.props.type === 'admin' ?
              <>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '1' })}
                    onClick={() => { this.toggle('1'); }}
                  >
                    <i className="icon-options-vertical"/> รายงานการขาย
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '2' })}
                    onClick={() => { this.toggle('2'); }}
                  >
                    <i className="icon-paper-clip"/> รายงานการซื้อขาย
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '3' })}
                    onClick={() => { this.toggle('3'); }}
                  >
                    <i className="icon-docs"/> รายงานใบกำกับภาษี
                  </NavLink>
                </NavItem>

                {/*<NavItem>
                  <NavLink
                      className={classnames({ active: this.state.activeTab === '4' })}
                      onClick={() => { this.toggle('4'); }}
                  >
                    <i className="icon-user"/> รายงานการขายแยกตามพนักงานขาย
                  </NavLink>
                </NavItem>*/}
              </>
              :
              <>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '3' })}
                    onClick={() => { this.toggle('3'); }}
                  >
                    <i className="icon-docs"/> รายงานใบกำกับภาษี
                  </NavLink>
                </NavItem>
             </>
          }

          {
            PORT === 9043 && this.props.type === 'admin' ?
                <NavItem>
                  <NavLink
                      className={classnames({ active: this.state.activeTab === '4' })}
                      onClick={() => { this.toggle('4'); }}
                  >
                    <i className="icon-docs"/> สรุปยอดขาย
                  </NavLink>
                </NavItem>
                : null
          }


        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <SellReport />
          </TabPane>

          <TabPane tabId="2">
            <TradingReport />
          </TabPane>

          <TabPane tabId="3">
            <TaxInvoiceReport />
          </TabPane>

          {/*<TabPane tabId="4">
            <SellEmployeeReport />
          </TabPane>*/}

          <TabPane tabId="4">
            <SellSummaryReport />
          </TabPane>

        </TabContent>

      </div>
    )
  }

}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  type: state.auth.type,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(ReportTab);

