import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import {connect} from 'react-redux' ;
import {loginUserSuccess, loginUserFailure} from '../../actions/index';
import axios from 'axios'
import { APIURL } from '../../config/config'
import { AlertError } from '../Alert/Alert'
import {CarSellUtil, CarsUtil} from "../../utils/carSellUtil"; // WEBURL

class Login extends Component {

  constructor(props){
     super(props)
     this.state = {
       username: '',
       password: '',
       errors: [],
       authen_errors: {},
       isLoading: false,
       mToken: '',
       logo_image: '',
       background_image: '',
       company_name: ''
     }
     this.onSubmit = this.onSubmit.bind(this);
     this.onChange = this.onChange.bind(this);
     this.loadLoginInfo = this.loadLoginInfo.bind(this);
  }

  componentDidMount () {
    this.loadLoginInfo()

  }

  loadLoginInfo() {
    axios.get(APIURL + '/signin/info').then(res=> {
      let data = res.data
      let company_setting = data.company_setting

      // console.log(company_setting)

      if (company_setting !== null) {
        this.setState({
          logo_image: company_setting.logo_image === undefined ? '': company_setting.logo_image,
          background_image: company_setting.background_image === undefined ? '' : company_setting.background_image,
          company_name: company_setting.company_name
        })
      }

    }).catch(err => {
      console.log('err:', err)
    })
  }

  onSubmit(e) {
    e.preventDefault();

    // var that = this ;
    const user = this.refs.username.value ;
    const pass = this.refs.password.value ;

    //alert(input_user.value +" :: "+input_pass.value);
    let errorsx = [];

    if(user==='' ){
      errorsx.push('username') ;
    }
    if(pass===''){
      errorsx.push('password') ;
    }

    this.setState({errors: errorsx});

    if (errorsx.length === 0){

        let logo_image = this.state.logo_image

        // console.log('logo_image=', logo_image)
        // console.log(response.data)

        axios.post(APIURL + '/signin/', {
          user: user,
          pass: pass
        })
        .then(response => {
           this.setState({authen_errors:{}, isLoading: true});
           // console.log(response.data)
           let type = response.data.user.type
           let branch_id = response.data.user.branch_id

           // console.log('branch_id=', branch_id, typeof branch_id)

          this.props.onLoginSuccess(response.data, logo_image, type, branch_id);
           // that.props.history.push('/dashboard');
            CarsUtil.removeCarSelect()
            CarSellUtil.removeCarSellSelect()

           if(response.data.user.type === 'admin'){
             this.props.history.push('/dashboard');
           }else {
             this.props.history.push('/cars/list/1');
           }
        })
        .catch(error => {
          console.log("error:"+error+":")
          //alert(":"+error+":")
          if(error==='Error: Network Error'){
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            AlertError('พบข้อผิดพลาด 801 : เครื่องเซิร์ฟเวอร์หยุดทำงาน')
          }else{
            this.props.onLoginFail(error);
            this.setState({authen_errors: error, isLoading: false})
            AlertError('ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง')
          }

        })
    }
  }

  onChange(e){
    this.setState({ [e.target.name]: e.target.value });
    this.setState( {isLoading: false});
  }

  render() {
    const {
      logo_image,
      background_image
    } = this.state

    // "url('"++"')"
    // "url('../img/bg.jpg')"
    let image_url = logo_image === '' ? 'img/logo.png' : logo_image
    let bg_url = background_image === '' ? "url('../img/bg.jpg')" : "url('"+background_image+"')"
    // let bg_url = "url('../img/bg.jpg')"
    // console.log('bg_url=', bg_url)

    return (
      <div className="app flex-row align-items-center"
           style={{backgroundImage: bg_url, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}
      >

        {
          logo_image === '' ? 'loading...'  : (
            <div className="container" >
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="card-group mb-0">
                    <div className="card p-4">
                      <div className="card-block">
                        <form className="form-horizontal" onSubmit={this.onSubmit}>

                          <center>
                            <img
                              src={image_url}
                              style={{maxWidth: 250, maxHeight: 150}}
                            />
                          </center>

                          <h5 className="mt-3 text-center">{this.state.company_name}</h5>

                          <p className="text-muted">Sign In to your account</p>

                          <div className="input-group mb-3">
                          <span className="input-group-addon">
                            <i className="icon-user"></i>
                          </span>
                            <input name="username"
                                   ref="username" type="text"
                                   className={this.state.errors.indexOf('username')!==-1?'form-control error': 'form-control' }
                                   placeholder="Username"
                                   onChange={(event) => this.onChange(event)}
                            />

                          </div>
                          <div className="input-group mb-4">
                            <span className="input-group-addon"><i className="icon-lock"></i></span>
                            <input name="password"
                                   ref="password" type="password"
                                   className={this.state.errors.indexOf('password')!==-1?'form-control error': 'form-control' }
                                   placeholder="Password"
                                   onChange={(event) => this.onChange(event)}
                            />
                          </div>
                          <div className="row">
                            <div className="col-6">

                            </div>
                            <div className="col-6 text-right">
                              {/*<button type="button" className="btn btn-link px-0">Forgot password?</button>*/}
                              <button type="submit"
                                      className="btn btn-primary px-4"
                                      disabled={false}>
                                Login
                              </button>
                            </div>
                          </div>
                        </form>

                        <br/><br/>
                        {/*<form className="form-horizontal">
                    <div className="row">
                      <div className="col-12">
                        <a  className="btn btn-link btn-success px-0"
                            href={WEBURL+"รถมาใหม่.html"}
                            target="_blank"
                            style={{color: '#ff0000'}}
                            rel="noopener noreferrer" >เว็บไซต์เต็นท์รถ</a>
                      </div>
                      </div>
                    </form>*/}
                      </div>
                    </div>
                    <div className="card card-inverse card-primary py-5 d-md-down-none" style={{ width: 44 + '%' }}>
                      <div className="card-block text-center">
                        <div>
                          <h6>BossCarSoft</h6>
                          <p>
                            ซอฟท์แวร์บริหารจัดการเต้นท์รถมือสอง
                          </p>
                          <p style={{ textAlign: 'left' }}>
                            - บันทึกค่าใช้จ่ายการซ่อมบำรุงและค่าใช้จ่ายอื่นๆได้ และแยกตามสาขาได้ <br />
                            - ควบคุมระบบการส่งซ่อมบำรุงรถยนต์ได้ทุกสาขา และระบบการแจ้งเตือนเมื่อรถซ่อมเสร็จบนปฎิทินงานซ่อม <br />
                            - บริหารจัดการลูกหนี้ทางการค้าที่มีการค้างเงินดาวน์และลูกหนี้นอกการค้าได้ทั้งหมด <br />
                            - บริหารจัดการเจ้าหนี้ทางการค้าที่มีการวางบิลงานซ่อมและระบบเตือนบนปฎิทิน

                          </p>

                          รับทำ chatbot (line, facebook messanger)<br/> ช่วยขายรถมือสอง
                          <br/>
                          <br/>
                          ติดต่อสอบถาม lineID : bchaichon <br/>
                          หรือโทร: 098-639-4588

                          {/*<button type="button" className="btn btn-primary active mt-3" >
                        สมัครใช้งาน
                      </button>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }

      </div>
    );
  }
}

/*Login.propTypes = {
    onLoginSuccess: PropTypes.func,
    onLoginFail: PropTypes.func,
    user:  PropTypes.object,
    token: PropTypes.string
}

Login.contextTypes = {
  router: PropTypes.object.isRequired
}*/

const mapStateToProps = (state) => ({
    user: state.user,
    token: state.token,
    isAuthenticated: state.isAuthenticated
})

const mapDispatchToProps = (dispatch) => ({
    onLoginSuccess(data, logo_image, type, branch_id) {
        dispatch(loginUserSuccess(data.token,JSON.stringify(data.user), logo_image, type, branch_id))
    },
    onLoginFail(error) {
        dispatch(loginUserFailure(error))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);
